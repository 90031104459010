import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import { Link } from "react-router-dom";
import PaginationComponent from "../BuyUsedCar/PaginationComponent";
import imgPlaceholder from "../../img/imageP.png";

export default function NewArrival() {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({});

  let baseurl = "https://akk.net.in:4000";
  // let baseurl = "http://localhost:4000";

  const getdata = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({ limit: 40, page: currentPage }), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl}/buyUsedCar/new-arrivals`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      console.log(data);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  const onPageChange = () => {
    setCurrentPage((p) => p + 1);
    setData("");
  };

  const LoadingSpinner = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "90vh" }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  useEffect(() => {
    getdata();
  }, [currentPage]);

  return (
    <div>
      <Navbar />

      <div class="container">
        <div style={{ textAlign: "center", width: "100%", fontSize: "1.2rem" }}>
          New Arrival
        </div>

        {/* <!--Breadcrumb--> */}
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              New-Arrival
            </li>
          </ol>
        </nav>
        {/* <!--Breadcrumb--> */}
      </div>

      <div class="bg4">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="row">
                {/* <!--PRODUCT LISTINGS--> */}

                {data && data.data && data.data.length > 0 ? (
                  <>
                    {data.data.map((item) => {
                      return (
                        <div class="col-lg-4 col-md-6">
                          <div class="featured_car1">
                            <div
                              className="feature_car1_img"
                              style={{
                                backgroundImage: `url(${
                                  item.images && item.images.length > 0
                                    ? item.images[0]
                                    : imgPlaceholder
                                })`,
                                backgroundSize: "cover", // Ensures the image covers the element
                                backgroundPosition: "center", // Centers the image
                              }}
                            ></div>
                            <div class="feature_car1_info2 text-left">
                              <Link
                                to={`/buy-used-cars/${item._id}`}
                                class="text-truncate"
                                href="#"
                              >
                                {item.car_name}
                              </Link>
                              <div
                                style={{
                                  display: "grid",
                                  flexDirection: "column",
                                  padding: "0.1px",
                                  gap: "0px",
                                }}
                              >
                                <p style={{ margin: 0, padding: 0 }}>
                                  Tramsmission : {item.engine_type}{" "}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Year : {item?.year}{" "}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Color : {item?.color}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Make : {item?.make}
                                </p>
                              </div>
                              <div style={{ float: "right", display: "block" }}>
                                <Link to={`/send-inquiry?id=${item._id}`}>
                                  {" "}
                                  <button class="btn btn-primary btn-sm">
                                    Submit Inquiry
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <LoadingSpinner></LoadingSpinner>
                )}

                {/* <!--PRODUCT LISTINGS END--> */}
                <PaginationComponent
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastPage={data?.totalPages}
                  total={100}
                ></PaginationComponent>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <BurgerMenu />
    </div>
  );
}
