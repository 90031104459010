import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
// import css
import "./home.css";
import "./owl.crousel.min.css";
import "./owl.theme.default.min.css";

// import all Images

import icon from "../../img/icon.webp";
import image4 from "../../img/image4.webp";
import quoteIcon from "../../img/quote-icon.webp";
import slide1 from "../../img/slide1.webp";
import blogImage2 from "../../img/blog-image2.webp";
// import new icons;
import BestPrice from "../../newIcon/best-price.webp";
import Hasel from "../../newIcon/hassle-free.webp";
import certifi from "../../newIcon/certificate.webp";
import Japan from "../../newIcon/japan.png";

// import owlcrousel
import OwlCarousel from "react-owl-carousel";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import ReactHtmlParser from "react-html-parser";

import Card from "../Card/Card";
import { BulletList, List } from "react-content-loader";

const options = {
  loop: true,
  center: true,
  responsiveClass: true,
  nav: true,
  items: 3,
  margin: 20,
  autoplay: false,
  dots: false,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1170: {
      items: 3,
      nav: true,
    },
  },
};

export default function Home() {
  const currentURL = window.location.href;
  const [data, setData] = useState(null);
  const [crouseldata, setCrouseldata] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState([]);
  const [wpBlog, setWPBlog] = useState([]);

  let baseurl = "https://akk.net.in:4000";
  const getdata = async () => {
    try {
      let res = await fetch(`${baseurl}/homepage/getHomepagebyUser`);
      // console.log(res)
      let data = await res.json(res);
      // console.log(data);
      setData(data.homepage[0]);
    } catch (e) {
      console.log(e, "er");
      alert(e);
    }
  };

  const getTestimonialData = async () => {
    try {
      let res = await fetch(`${baseurl}/testimonial/getTestimonialByUser`);
      let data = await res.json(res);
      setCrouseldata([...data.testimonials]);
    } catch (e) {
      console.log(e);
    }
  };

  const getFeaturedBlogs = async () => {
    try {
      let res = await fetch(`${baseurl}/blogs/getFeaturedBlog`, {
        method: "POST",
        // Additional headers or body if needed
      });
      let data = await res.json(res);
      setFeaturedBlog([...data.data]);
    } catch (e) {
      console.log(e);
    }
  };

  const getWPBlogforcrousel = async () => {
    try {
      let res = await fetch(`${baseurl}/WP-blog/get-wordpress-for-user`, {
        method: "POST",
        // Additional headers or body if needed
      });
      let data = await res.json(res);
      setWPBlog([...data.data]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getTestimonialData();
      getFeaturedBlogs();
      getWPBlogforcrousel();
    }, 1000);
    getdata();
  }, []);
  // console.log(featuredBlog)
  // console.log(data);
  return (
    <div>
      <Helmet>
        <title>{data ? data.metaTitle : ""}</title>
        <meta name="description" content={data ? data.metaDescription : ""} />
        <meta name="keywords" content={data ? data.metaKeywords : ""} />
        <link rel="canonical" href={currentURL}></link>
      </Helmet>

      <Navbar />

      <div
        className="home-banner"
        style={{ backgroundImage: `url(${data ? data.bannerImage : ""})` }}
      >
        <div className="scroll-text">
          SCROLL DOWN
          <div className="scrollbox">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="container">
          <div className="home-banner-content">
            <h1>{data ? data.title : ""}</h1>
            {data ? ReactHtmlParser(data.description) : ""}
            <NavLink
              to="/sellcar"
              className="btn btn-primary btn-lg"
              style={{ border: "1px solid transparent" }}
            >
              {" "}
              Sell Your Old Car
            </NavLink>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavLink
              to="/buy-used-parts"
              className="btn btn-primary btn-lg"
              style={{ border: "1px solid transparent" }}
            >
              Buy Used Parts
            </NavLink>
          </div>
        </div>
      </div>

      <div className="bg1">
        <div className="bg2">
          <div className="row">
            <div className="col-md-10 col-lg-6">
              {data?.videoLink && data?.videoLink !== "" ? (
                <div
                  class="embed-responsive embed-responsive-16by9"
                  style={{ marginTop: "-10rem" }}
                >
                  <iframe
                    width="100%"
                    height="400"
                    src={`https://www.youtube.com/embed/${data?.videoLink}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              ) : (
                <img
                  src={data ? data.aboutUsImage : ""}
                  alt="About Akk Featured "
                  width="95%"
                  loading="lazy"
                  style={{ marginTop: "-10rem" }}
                />
              )}
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="featured-content1">
                <h2>About Us</h2>
                {data ? ReactHtmlParser(data.aboutUs) : ""}
                <Link
                  to="/aboutus"
                  className="btn btn-primary btn-lg"
                  style={{
                    marginLeft: "35px",
                    border: "1px solid transparent",
                  }}
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Adding new section for Video and Image  START*/}

      {/* Adding new section for Video and Image  END*/}
      <div className="row" style={{ maxWidth: "100%", margin: "0" }}>
        <div className="home-why-akk-iconsbox">
          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-md-6">
              <img
                src={BestPrice}
                loading="lazy"
                height="80"
                alt="why AKK icons"
              />
              <h5>{data ? data.whyAkk1Title : ""}</h5>
              <p>{data ? data.whyAkk1Description : ""}</p>
            </div>
            <div className="col-md-6">
              <img src={Hasel} loading="lazy" height="80" alt="why AKK icons" />
              <h5>{data ? data.whyAkk2Title : ""}</h5>
              <p>{data ? data.whyAkk2Description : ""}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img
                src={certifi}
                loading="lazy"
                height="80"
                alt="why AKK icons"
              />
              <h5>{data ? data.whyAkk3Title : ""}</h5>
              <p>{data ? data.whyAkk3Description : ""}</p>
            </div>
            <div className="col-md-6">
              <img src={Japan} loading="lazy" height="80" alt="why AKK icons" />
              <h5>{data ? data.whyAkk4Title : ""}</h5>
              <p>{data ? data.whyAkk4Description : ""}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 bg3">
          <div className="home-why-akk">
            <h3>Why Akk!</h3>
            {data ? ReactHtmlParser(data.WhyAkk) : ""}
          </div>
        </div>
        <div
          className="col-lg-6 col-md-12"
          style={{
            padding: "0",
            backgroundImage: `url(${data ? data.whyAkkImage : ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Mobile view */}
          <div className="home-why-akk-iconsbox-mobile">
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="col-md-6">
                <img src={BestPrice} height="80" alt="why AKK icons" />
                <h5>{data ? data.whyAkk1Title : ""}</h5>
                <p>{data ? data.whyAkk1Description : ""}</p>
              </div>
              <div className="col-md-6">
                <img src={Hasel} height="80" alt="why AKK icons" />
                <h5>{data ? data.whyAkk2Title : ""}</h5>
                <p>{data ? data.whyAkk2Description : ""}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img src={certifi} height="80" alt="why AKK icons" />
                <h5>{data ? data.whyAkk3Title : ""}</h5>
                <p>{data ? data.whyAkk3Description : ""}</p>
              </div>
              <div className="col-md-6">
                <img src={Japan} height="80" alt="why AKK icons" />
                <h5>{data ? data.whyAkk4Title : ""}</h5>
                <p>{data ? data.whyAkk4Description : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Card />
      {/* why recycle */}
      <div
        className="home-recycle-akk"
        style={{
          backgroundImage: `url(${data ? data.whyRecycleImage : " "})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-4"></div>
            <div className="col-lg-5 col-md-8">
              <div className="home-recycle-akk-container">
                <h5>Why Recycle with AKK</h5>
                {data
                  ? ReactHtmlParser(data.whyRecycle)
                  : "Why Recycle with AKK"}

                <Link
                  className="btn btn-primary btn-lg"
                  style={{ border: "1px solid transparent" }}
                  to="/aboutus"
                >
                  {" "}
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h3 className="a1 pd1">Purchase Used Automotive Spare Parts.</h3>
          <div className="row">
            <div className="col-lg-7 col-md-12 d-lg-none d-block">
              <img
                src={data ? data.purchasedUsedPartsImage : image4}
                alt="AKK Purchase Used Automotive Spare Parts"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="a2 text-highlighter1">
                {data ? ReactHtmlParser(data.purchasedUSedParts) : ""}
              </div>
            </div>
            <div className="col-lg-7 col-md-12 d-none d-lg-block">
              <img
                src={data ? data.purchasedUsedPartsImage : image4}
                alt="AKK Purchase Used Automotive Spare Parts"
                width="100%"
                height="100%"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h4 className="a1 text-center">Testimonial</h4>

          {/* <!-- TESTIMONIALS --> */}
          <section className="testimonials">
            <div className="container">
              {crouseldata.length !== 0 ? (
                <OwlCarousel
                  className="owl-theme"
                  id="customers-testimonials"
                  {...options}
                  loop
                  center
                  nav={true}
                  autoplay={true}
                  margin={20}
                  dots={false}
                >
                  {crouseldata.map((item) => {
                    const { _id, title, description, thumbnail } = item;
                    return (
                      <section key={_id} className="dvMainSlider">
                        <div className="item">
                          <div className="shadow-effect">
                            <img
                              src={quoteIcon}
                              className="img-circle"
                              alt="AKK Testimonials"
                              width="100%"
                              height="100%"
                              loading="lazy"
                            />
                            <p className="">{description}</p>
                            <Link className="btn btnPrimary mb-3 mt-sm-3">
                              {title}
                            </Link>
                          </div>
                        </div>
                      </section>
                    );
                  })}
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </section>
          {/* <!-- END OF TESTIMONIALS --> */}
        </div>
      </div>

      {/* latest news section */}
      <div className="home-blogs">
        <div className="home-blogs-slider">
          <img src={slide1} loading="lazy" width="100%" height="100%" alt="" />
        </div>
        {/* latest News */}
        <div className="container home-news-container">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-block"></div>
            <div className="col-lg-6">
              <div style={{ textAlign: "right", marginBottom: "45px" }}>
                <Link
                  className="btn btn-primary btn-lg"
                  style={{ border: "1px solid transparent" }}
                  to="/news"
                >
                  View All
                </Link>
              </div>
              <div className="home-blogs-container">
                <h5>
                  <span>Latest News</span>
                  {featuredBlog.length !== 0 ? featuredBlog[0].title : ""}
                </h5>
                <p>{featuredBlog.length !== 0 ? featuredBlog[0].body : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog crousel section */}

      {/* s */}
      <div className="bg5">
        <div className="row">
          <div className="col-md-5">
            <div className="home-blogs2">
              <h5>Blogs</h5>
            </div>
          </div>
          <div className="col-md-7">
            <div className="home-blogs2-slider">
              <section className="blogs">
                {wpBlog.length !== 0 ? (
                  <OwlCarousel
                    id="customers-testimonials1"
                    className="owl-theme"
                    {...options}
                  >
                    {wpBlog.map((blog, index) => {
                      const { _id, Image, link, title } = blog;
                      return (
                        <div key={_id} className="item">
                          <div className="shadow-effect1">
                            <img
                              className="img-circle"
                              src={Image}
                              alt=""
                              width="100%"
                              height="300px"
                              loading="lazy"
                            />
                            <p>
                              {title}

                              <a href={link} target="_blank">
                                Read More{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  ""
                )}
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* e */}
      <Footer />
    </div>
  );
}
