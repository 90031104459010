import axios from "axios";

import "./parts.css";
import React, { useEffect, useState } from "react";
// import ThumbnailSlider from "./ThumbanilCrousel";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import imgPlaceholder from "../../img/imageP.png";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MultiSelectDropdown from "./MultiSelect";

const modalContentStyle = {
  backgroundColor: "#fefefe",
  padding: "20px",
  borderRadius: "5px",
  border: "1px solid grey",
  //   boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
};

// let baseurl = "http://localhost:4000";
let baseurl = "https://akk.net.in:4000";
const UsedPartBeforeHandInquiry = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const [mainImage, setMainImage] = useState(""); // State to keep track of main image
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [data, setData] = useState("");
  const navigate = useNavigate();
  let url2 = "https://akk.net.in:4000/buyUsedCar/getcarById";

  const fetchData = async () => {
    try {
      const response = await axios.post(url2, {
        id: id,
      });
      console.log(response.data.data, "res");

      setData(response.data.data);
      setModalData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // let baseurl = "https://akk.net.in:4000";

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    message: "",
    email: "",
  });

  const resetFormData = () => {
    setFormData({
      name: "",
      phoneNo: "",
      message: "",
      email: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //post  api for inquiry;

  const sendDataToBackend = async (newFormData) => {
    try {
      const response = await fetch(`${baseurl}/book-before/save-inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({
          ...newFormData,
          car_id: modalData.car_id,
          elm_ID: modalData._id,
          car_name: modalData.car_name,
        }), // Convert the data to JSON format
      });
      const data = await response.json();

      if (data?.success == "1") {
        setFormData({
          name: "",
          phoneNo: "",
          message: "",
          email: "",
        });
        alert("Inquiry sent successfully");
        return navigate("/success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNo = (phoneNo) => {
    // Check if phoneNo is a number and has 9 to 10 digits
    return /^\d{9,10}$/.test(phoneNo);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name &&
      !formData.email &&
      !formData.phoneNo &&
      !formData.message
    ) {
      alert("Please fill all fields");
      return;
    }
    const { name, phoneNo, message, email } = formData;

    if (!name || !phoneNo || !message || !email) {
      alert('All fields are required.');
      return;
    }

    if (!validateEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!validatePhoneNo(phoneNo)) {
      alert('Phone number must be 9 to 10 digits.');
      return;
    }
   
    // console.log("Form submitted:", formData, modalData);

    if (selectedOptions?.length === 0) {
      alert("Please select Options for Inquiry");
      return;
    }
    console.log(selectedOptions);
    const optionsList = selectedOptions
      .map((option) => `- ${option}`)
      .join("\n");
    const newMsg = `${formData.message} (Selected Category)\n${optionsList}`;
    let newFormData = { ...formData, message: newMsg };
    if (!recaptchaValue) {
      alert("Check Recaptcha");
      return;
    }
    await sendDataToBackend(newFormData);

    handleCloseModal();
  };
  // Function to handle opening modal

  // Function to handle closing modal
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData("");
    resetFormData();
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <>
      <Navbar />
      <div className="container " style={{ marginTop: "2%" }}>
        <div className="row">
          {/* Left column */}
          <div className="col-md-6 ">
            {/* <ThumbnailSlider /> */}
            <div style={modalContentStyle}>
              <h2>Car Inquiry </h2>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label htmlFor="firstName"> Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label htmlFor="email">Contact No:</label>
                  <input
                    type="number"
                    id="email"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label htmlFor="message">Message:</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <MultiSelectDropdown
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                ></MultiSelectDropdown>
                <ReCAPTCHA
                  sitekey="6LdosFgpAAAAAEulS6fgoy48JE9TG2LNfhvzCdDY"
                  onChange={handleRecaptchaChange}
                />
                {/* <button type="submit">Submit</button> */}
              </form>
            </div>
          </div>
          {/* Right column */}
          <div className="col-md-6" style={{ padding: "2%" }}>
            <div className="container">
              <div className="row">
                {/* Image */}
                <div className="col-md-4">
                  <img
                    src={data&&(data?.images?.length>0)?data.images[0]:imgPlaceholder}
                    alt="Car Imageq"
                    className="img-fluid"
                  />
                </div>

                {/* Content */}
                <div className="col-md-8">
                  <div className="row">
                    {/* Car name and year */}
                    <div className="col">
                      <h4>
                        {data?.car_name ?? ""} {data?.year ?? ""}
                      </h4>
                    </div>
                  </div>

                  {/* <div className="row">
                    Price
                    <div className="col">
                      <h4 style={{ color: "#f6a700" }}>{data?.price ?? ""}</h4>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col">
                      <p>Available in stock</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <p>Manufacture : {data.make}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <p>Tramsmission : {data.engine_type}</p>
                    </div>
                  </div>

                  <div className="row">
                    {/* Color */}
                    <div className="col">
                      <p>Color : {data.color}</p>
                    </div>
                  </div>

                  <div className="row">
                    {/* Send inquiry button */}
                    <div className="col" style={{ marginTop: "4%" }}>
                      <button
                        className="btn btn-primary ms-2"
                        style={{ border: "1px solid transparent" }}
                        onClick={(e) => handleSubmit(e)}
                      >
                        {" "}
                        Submit Inquiry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UsedPartBeforeHandInquiry;
