import React, { useState } from "react";
import "./searchBarCar.css"; // Import CSS file for styling

function SearchBarForCar({
  selectOption,
  setSelectOption,
  handelSearch,
  name,
  make,
  year,getdataName
}) {
  const [nameOption, setNameOption] = useState(name); // State for the name select
  const [makeOption, setMakeOption] = useState(""); // State for the make select
  const [yearOption, setYearOption] = useState(""); // State for the year select

  // Event handler for when the name select value changes
  const handleNameChange = (event) => {
    setNameOption(event.target.value); // Update the state with the selected option
    setSelectOption({ ...selectOption, name: event.target.value });
  };

  // Event handler for when the make select value changes
  const handleMakeChange = (event) => {
    setMakeOption(event.target.value); // Update the state with the selected option
    setSelectOption({ ...selectOption, make: event.target.value });
    getdataName(event.target.value )
  };

  // Event handler for when the year select value changes
  const handleYearChange = (event) => {
    setYearOption(event.target.value); // Update the state with the selected option
    setSelectOption({ ...selectOption, year: event.target.value });
  };

  return (
    <div style={{padding:'1%'}}>
    <div
      className="custom-container"
      style={{
        border: "1px solid transparent",
        padding: "2%",
        // boxShadow:
        //   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
        //
        boxShadow: "0px -2px 10px 5px rgba(0, 0, 0, 0.15)",
       
        borderRadius: "10px",
      }}
    >
      <div className="custom-item">
        {/* Make */}
        <select
          style={{ width: "70%", padding: "5px" }}
          value={makeOption}
          onChange={handleMakeChange}
          className="form-select form-select-lg"
          id="inputState"
        >
          <option value="">Make</option>
          {make ? (
            make.map((e) => {
              return <option value={e}>{e} </option>;
            })
          ) : (
            <option value="">api error</option>
          )}
        </select>
      </div>
      <div   className="custom-item col-sm-6 col-md-4 col-lg-3">
        {/* Name */}
        <select
          style={{ width: "70%", padding: "5px" }}
          value={nameOption}
          onChange={handleNameChange}
          className="form-select form-select-lg"
          id="inputState"
        >
          <option value="">Car Name</option>
          {name ? (
            name.map((e) => {
              return <option value={e.car_name}>{e.car_name} </option>;
            })
          ) : (
            <option value="">api error</option>
          )}
        </select>
      </div>
      
      <div className="custom-item">
        {/* Year */}
        <select
          style={{ width: "70%", padding: "5px" }}
          value={yearOption}
          onChange={handleYearChange}
          className="form-select form-select-lg"
          id="inputState"
        >
          <option value="">Year</option>
          {year ? (
            year.map((e) => {
              return <option value={e}>{e} </option>;
            })
          ) : (
            <option value="">api error</option>
          )}
        </select>
      </div>
      <div className="custom-item">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => handelSearch()}
        >
          Search
        </button>
      </div>
    </div>
    </div>
  );
}

export default SearchBarForCar;
