import React from "react";

const PaginationForSearchusedPArt = ({
  currentPage,
  lastPage,
  total,
  onPageChange,
  setCurrentPage,
}) => {
  // Define the number of page buttons to show
  const maxPageButtonsToShow = 5; // Adjust this value as needed
// console.log(currentPage,lastPage)
  // Function to handle pagination button click
  const handleClick = (pageNumber) => {
    // Call the onPageChange function to update the current page
    onPageChange(pageNumber);
  };

  // Generate an array of page numbers based on the current page and the maximum number of buttons to show
  const generatePageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageButtonsToShow / 2)
    );
    let endPage = Math.min(lastPage, startPage + maxPageButtonsToShow - 1);

    // Adjust startPage and endPage if they are at the beginning or end of the range
    if (endPage - startPage + 1 < maxPageButtonsToShow) {
      startPage = Math.max(1, endPage - maxPageButtonsToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  // Function to handle clicking on the previous or next button
  const handleNavigationClick = (direction) => {
    const newPageNumber =
      direction === "prev" ? currentPage - 1 : currentPage + 1;

    if (newPageNumber >= 1 && newPageNumber <= lastPage) {
      onPageChange(newPageNumber);
      setCurrentPage(newPageNumber);
    }
  };

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => handleNavigationClick("prev")}
          >
            Previous
          </button>
        </li>
        {generatePageNumbers().map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item ${pageNumber === currentPage && "active"}`}
          >
            <button
              className="page-link"
              onClick={() => handleClick(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === lastPage && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => handleNavigationClick("next")}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationForSearchusedPArt;
