import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import "./blog.css";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../../img/image1.webp";
import { Helmet } from "react-helmet";
import Card from "../Card/Card";

let baseurl = "https://akk.net.in:4000";

export default function BlogList() {
  const [blog, setBlog] = useState([]);
  const [page,setPage]=useState('')
  const [visibleBlogs, setVisibleBlogs] = useState(9);
  const loadMoreAmount = 9;
  const loadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + loadMoreAmount);
  };
  let token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MjBlMGUyZDE4NzMxZDkyYjZjNjk3NyIsImlhdCI6MTY5NzEwNTE2OSwiZXhwIjoxNjk3MTQ4MzY5fQ.3c8ngN2kIdtG43m9LGvW-b5oRbJsY5GZXdfzB-a4g5A";
  const getBlog = async () => {
    try {
      const res = await fetch(`${baseurl}/blogs/getblogs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token }),
      });

      if (res.ok) {
        const responseData = await res.json();
        // console.log(responseData);
        setBlog(responseData.blogs);
        // Handle the response data as needed
      } else {
        console.log("Failed to get a blog.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBlogListPage = async () => {
    try {
      // changes blogs converted into news ; 
      const res = await fetch(`${baseurl}/blogs/getBloglistpage`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        
      });

      if (res.ok) {
        const responseData = await res.json();
        // console.log(responseData,"re");
        setPage(responseData.data);
        // Handle the response data as needed
      } else {
        console.log("Failed to get a blog.");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const currentURL = window.location.href;

  // getBloglistpage
  useEffect(() => {
    getBlog();
    getBlogListPage()
  }, []);
 
  return (
    <div>
      <Helmet>
        <title>{page ? page.metaTitle : ""}</title>
        <meta
          name="description"
          content={
            page ? page.metaDescription : ""
          }
        />
        <meta
          name="keywords"
          content={page ? page.metaKeyword : ""}
          
        />
           <link rel="canonical" href={currentURL}></link>
      </Helmet>
      <Navbar />

      <div
        className="heading_banner_sm"
        style={{ backgroundImage: `url(${page?page.imageBanner:''})`, marginBottom: 0 }}
      >
        <div className="container"></div>
      </div>

      <div className="container" style={{ marginTop: "-14%" }}>
        <div className="row">
          {blog.length === 0
            ? "No blogs data"
            : blog.slice(0, visibleBlogs).map((e) => {
                return (
                  <div className="col-md-4 col-sm-6" key={e._id}>
                    <div className="blogs_card">
                      <div
                        className="blog_banner"
                        style={{
                          background: `url(${
                            blog.length !== 0 ? e.imageThumbnail : null
                          })`,
                         backgroundPosition:"center"
                        }}
                      ></div>
                      <div className="blog_intro_container">
                        <div className="blog_intro_title">{e.title}</div>
                        <p className="blog_intro_content">{e.body}</p>
                         
                          <Link to={`/news/${e.url}`}>Read More </Link>
                         
                      </div>
                    </div>
                  </div>
                );
              })}

          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <button href="#" className="btn btn-primary btn-lg" onClick={loadMore}>
              Load More
            </button>
          </div>
        </div>
      </div>

      <Card />

      <Footer />
    </div>
  );
}
