import React, { useEffect, useState } from "react";
let baseurl = "https://akk.net.in:4000";
export default function NewSearchBar({
  selectOptionForSearch,
  setSelectOptionForSearch,
  handelSearch,
}) {
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [year, setYear] = useState([]);
  const getdataMake = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      let res = await fetch(`${baseurl}/filter/get-make-name`, requestOptions);
      let data = await res.json();
      // console.log(data);
      setCarMake(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getCarModelByMake = async (value) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ make: value }),
      };

      let res = await fetch(
        `${baseurl}/filter/get-carModelName-by-make`,
        requestOptions
      );
      let data = await res.json();
      // console.log(data);
      setCarModel(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getYearbyNameandMake = async (make, name) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ make: make, name: name }),
      };

      let res = await fetch(`${baseurl}/filter/get-car-year`, requestOptions);
      let data = await res.json();
      // console.log(data);
      setYear(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMakeChange = (e) => {
    const { value } = e.target;
    if (!value) return;
    setSelectOptionForSearch({
      ...selectOptionForSearch,
      make: value,
      name: "",
      year: "",
    });
    getCarModelByMake(value);
  };

  const handleCarModelNameChange = (e) => {
    const { value } = e.target;
    if (!value) return;
    setSelectOptionForSearch({
      ...selectOptionForSearch,
      name: value,
      year: "",
    });
    
  };

  const handleYearChange = (e) => {
    const { value } = e.target;
    setSelectOptionForSearch({
      ...selectOptionForSearch,

      year: value,
    });
  };

  useEffect(() => {
    getdataMake();
    getYearbyNameandMake();
  }, []);

  return (
    <div style={{ padding: "1%" }}>
      <div
        className="custom-container"
        style={{
          border: "1px solid transparent",
          padding: "2%",
          // boxShadow:
          //   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
          //
          boxShadow: "0px -2px 10px 5px rgba(0, 0, 0, 0.15)",

          borderRadius: "10px",
        }}
      >
        <div className="custom-item">
          {/* Make */}
          <select
            style={{ width: "70%", padding: "5px" }}
            // value={makeOption}
            onChange={handleMakeChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Make</option>
            {carMake ? (
              carMake.map((e) => {
                return <option value={e}>{e} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>
        <div className="custom-item col-sm-6 col-md-4 col-lg-3">
          {/* Name */}
          <select
            style={{ width: "70%", padding: "5px" }}
            // value={nameOption}
            onChange={handleCarModelNameChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Car Name</option>
            {carModel ? (
              carModel.map((e) => {
                return <option value={e.car_name}>{e.car_name} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>

        <div className="custom-item">
          {/* Year */}
          <select
            style={{ width: "70%", padding: "5px" }}
            // value={yearOption}
            onChange={handleYearChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Year</option>
            {year ? (
              year.map((e) => {
                return <option value={e}>{e} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>
        <div className="custom-item">
          <button
            className="btn btn-primary btn-lg"
            onClick={() => handelSearch()}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}
