import { Route, Routes } from "react-router-dom";
import Home from "../Components/HomePage/Home";
import SellCarPage from "../Components/SellCarPage/SellCarPage";
import AboutPage from "../Components/AboutusPage/AboutPage";
import BuyUsedCar from "../Components/BuyUsedCar/BuyUsedCar";
import BlogList from "../Components/Blog/BlogList";
import BlogDetail from "../Components/Blog/BlogDetail";
import NotFound from "../Components/NotFound/NotFound";
import BuyUsedParts from "../Components/BuyUsedParts/BuyUsedParts";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "../Components/ContactUs/ContactUs";
import Faq from "../Components/Faq/Faq";
import SearchBuyUsedParts from "../Components/BuyUsedParts/SearchBuyUsedParts";
import DetailPageUsedParts from "../Components/BuyUsedParts/DetailPageUsedParts";
import DetailPageUsedCars from "../Components/BuyUsedCar/DetailPageUsedCars";
import SendInquiryPage from "../Components/SendInquiryPage";
import SendInquiryParts from "../Components/BuyUsedParts/SendInquiryParts";
import SpecialOffer from "../Components/SpecialOffAndNewArrival/SpecialOffer";
import NewArrival from "../Components/SpecialOffAndNewArrival/NewArrival";
import UsedPartBeforeHand from "../Components/UsedPartBeforeHAnd/UsedPartBeforeHand";
import UsedPartBeforeHandInquiry from "../Components/UsedPartBeforeHAnd/UsedPartBeforeHandInquiry";
import ErrorPage from "../Components/SuccesAndErrorPage/ErrorPage";
import SuccessPage from "../Components/SuccesAndErrorPage/SuccessPage";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/sellcar" element={<SellCarPage />} />
      <Route path="/aboutus" element={<AboutPage />} />
      <Route path="/buy-used-cars" element={<BuyUsedCar />} />
      <Route path="/buy-used-cars/:id" element={<DetailPageUsedCars />} />
      <Route path="/buy-used-parts" element={<BuyUsedParts />} />
      <Route path="/search-used-parts" element={<SearchBuyUsedParts />} />
      <Route path="/Details-of-used-parts" element={<DetailPageUsedParts />} />

      {/* Send INquiry  SendInquiryParts*/}
      <Route path="/send-inquiry" element={<SendInquiryPage />} />
      <Route path="/send-part-inquiry" element={<SendInquiryParts />} />

      {/* DetailPageUsedParts */}
      <Route path="/news" element={<BlogList />} />
      <Route path="/news/:id" element={<BlogDetail />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/frequently-asked-questions" element={<Faq />} />

      {/*  */}
      <Route path="/special-offer" element={<SpecialOffer />} />
      <Route path="/new-arrival" element={<NewArrival />} />

      {/* Book part brfore hand */}
      
      <Route path="/book-before" element={<UsedPartBeforeHand />} />
      <Route path="/book-before-inquiry" element={<UsedPartBeforeHandInquiry />} />
      
      {/*  */}
      <Route path="/success" element={<SuccessPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
