import React from "react";
import { Link } from "react-router-dom";
import errImg from "./404-default.webp";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
export default function ErrorPage() {
  return (
    <div>
      <Navbar />
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-10" style={{ margin: "5% 0 10% 0" }}>
            <img
              src={errImg}
              width="80%"
              height="auto"
              style={{ maxWidth: "400px", height: "auto" }}
              alt="Error"
            />
            <h1 className="a1">Oops! Page Not Found.</h1>
            <p className="a2">We cannot find the page you are looking for.</p>
            <Link to="/" className="btn btn-primary btn-special-cta">
              Back to Homepage
            </Link>{" "}
            <Link
              to="/contact-us"
              className="btn btn-secondary btn-special-cta"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
