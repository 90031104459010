import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ThumbnailSlider = () => {
  const [mainSlider, setMainSlider] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    asNavFor: mainSlider,
  };

  const dummyImages = [
    "https://cdni.autocarindia.com/utils/imageresizer.ashx?n=https://cms.haymarketindia.net/model/uploads/modelimages/Hyundai-Creta-180120241405.jpg&w=872&h=578&q=75&c=1",
    "https://spn-sta.spinny.com/blog/20231103174155/new-Tata-Safari-1160x653.webp?compress=true&quality=80&w=1200&dpr=2.6",
    "https://www.gaadihub.com/images/model/tata-harrier-1632974363.jpg",
    "https://www.autocar.co.uk/sites/autocar.co.uk/files/styles/gallery_slide/public/images/car-reviews/first-drives/legacy/100-best-luxury-cars.jpg?itok=wCsUrm0B",
    // Add more image links as needed
  ];

  return (
    <div>
      <Slider {...settings} ref={(slider) => setMainSlider(slider)}>
        {dummyImages?.map((e) => {
          return (
            <div style={{ width: "100%", overflow: "hidden" }}>
              <img src={e} width={"100%"} height="400px" />
            </div>
          );
        })}

        {/* <div>
          <img src="https://spn-sta.spinny.com/blog/20221004191046/Hyundai-Venue-2022.jpg?compress=true&quality=80&w=1200&dpr=2.6" alt="Image 3" />
        </div> */}
        {/* Add more images here */}
      </Slider>
      <div className="thumbnail-slider">
        <Slider
          dots={false}
          infinite={true}
          speed={900}
          slidesToShow={2}
          slidesToScroll={1}
          centerMode={true}
          focusOnSelect={true}
          asNavFor={mainSlider}
          swipeToSlide={true}
          arrows={true}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {dummyImages?.map((e) => {
            return (
              <div>
                <img src={e} width={"100%"} height="100px" />
              </div>
            );
          })}

          {/* <div>
            <img src="image3.jpg" alt="Thumbnail 3" />
          </div> */}
          {/* Add more thumbnails here */}
        </Slider>
      </div>
    </div>
  );
};

export default ThumbnailSlider;
