import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import person from "../../img/person.png";
import sf1 from "../../img/sf1.png";
import sf2 from "../../img/sf2.png";
import sf3 from "../../img/sf3.png";
import sf4 from "../../img/sf4.png";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import imgPlaceholder from "../../img/imageP.png";
import { Helmet } from "react-helmet";
let baseurl = "https://akk.net.in:4000";
let baseurl2 = "https://akk.net.in:4000";
let baseurl3 = "https://akk.net.in:4000";
function BuyUsedParts() {
  const navigate = useNavigate();

  const backgroundImageUrl = "https://picsum.photos/400/300.webp";

  const [categories, setCategories] = useState([]);
  const [getquesAns, setGetquesAns] = useState([]);
  const [offer, setOffer] = useState([]);
  const [carName, setCarName] = useState([]);
  const [partName, setPartName] = useState([]);
  const [partYear, setPartYear] = useState([]);
  const [partMake, setPartMake] = useState([]);
  const [newArrival, setNewArrival] = useState([]);

  const [renderqna, setRenderqna] = useState([]);
  const [page, setPage] = useState();
  const getcategories = async () => {
    try {
      let res = await fetch(`${baseurl}/faq/getfaqCategories`);
      let data = await res.json(res);
      //  console.log(data.faq,"getCategoriesAPI")
      setCategories(data.faq);
      return data.faq;
    } catch (e) {
      console.log(e);
    }
  };

  const getFaqQueAns = async () => {
    try {
      let res = await fetch(`${baseurl}/faq/getFaq`);
      let data = await res.json(res);
      // console.log(data.faq,"QNAgetAPI")
      setGetquesAns(data.faq);
      return data.faq;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    try {
      const categoriesData = await getcategories();
      const faqData = await getFaqQueAns();
      // Now that both API calls have completed, proceed to getQna.
      getqnaTorenderaccordingtocattegories(categoriesData, faqData);
    } catch (e) {
      console.log(e);
    }
  };

  const getqnaTorenderaccordingtocattegories = (categoriesData, faqData) => {
    const catID = categoriesData.find((e) => e.name === "used-parts");

    // console.log(catID, "getqnaTorenderaccordingtocattegories");
    if (catID) {
      // Filter FAQs by category ID
      const filteredData = faqData.find((e) => e.categoryId === catID._id);
      const data = filteredData ? filteredData.faq : [];

      // Set renderqna with the filtered FAQ data
      setRenderqna([...data]);
    }
  };

  const getNewArrivals = async () => {
    try {
      let res = await fetch(`${baseurl2}/buyUsedCar/new-arrivals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify({ limit: 4 }), // Include data in the request body if needed
      });

      let data = await res.json();

      setNewArrival(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getOffer = async () => {
    try {
      let res = await fetch(`${baseurl2}/buyUsedCar/special-offer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify({ limit: 4 }), // Include data in the request body if needed
      });

      let data = await res.json(res);
      //  console.log(data.faq,"getCategoriesAPI")
      setOffer(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  // GET PART NAME;
  const getdataName = async (make, engine_model) => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({ make: make, engine_model: engine_model }), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl3}/filter/get-part-name`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setPartName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataYear = async (make, engine_model, name) => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({
          make: make,
          engine_model: engine_model,
          name: name,
        }), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl3}/filter/get-part-year`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      console.log(data);
      setPartYear(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataMake = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl3}/filter/get-make-name-part`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setPartMake(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getCarNameByMAKE = async (value) => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({ make: value }), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl3}/filter/get-carName-by-makeName-part`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setCarName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getPageContent = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl3}/buyUsedPart/get-buyUsedPartPage`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON

      setPage(data.buyUsedPart);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOffer();
    getNewArrivals();
    getdataName();
    getdataYear();
    getdataMake();
    getPageContent();
  }, []);
  // console.log(offer, "S", newArrival);
  // for search
  let obj = {
    year: null,
    manufacturer: null,
    vehicleName: null,
  };

  const [option, setOption] = useState({
    name: "",
    make: "",
    year: "",
    engine_model: "",
  });

  const handelNavigate = (event) => {
    event.preventDefault();

    if (
      option.make == "" &&
      option.year == "" &&
      option.name == "" &&
      option.engine_model == ""
    ) {
      alert("Please select an option");
      return;
    }
    // console.log("Click");
    return navigate(
      `/search-used-parts?make=${option.make}&year=${option.year}&name=${option.name}&engine_model=${option.engine_model}`
    );
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  const currentURL = window.location.href;

  return (
    <div>
      <Helmet>
        <title>{page ? page.metaTitle : ""}</title>
        <meta name="description" content={page ? page.metaDescription : ""} />
        <meta name="keywords" content={page ? page.metaKeyword : ""} />
        <link rel="canonical" href={currentURL}></link>
      </Helmet>
      <Navbar />
      <div
        className="heading_banner_auto sell_car_banner"
        style={{ paddingBottom: "0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <h1 className="a5" style={{ paddingTop: "2rem" }}>
                {page ? page.bannerTitle : ""}
              </h1>
            </div>
            <div className="col-lg-4 col-md-4">
              <img
                src={page ? page.bannerImage : ""}
                height="300"
                width="auto"
                loading="lazy"
                alt="Featured Buy used car parts"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="search_box">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <select
                id="inputState"
                className="form-select form-select-lg"
                onChange={(e) => {
                  setOption({
                    ...option,
                    make: e.target.value,
                    name: "",
                    year: "",
                    engine_model: "",
                  });
                  getCarNameByMAKE(e.target.value);
                }}
              >
                <option value="">Maker</option>
                {partMake ? (
                  partMake.map((e) => {
                    return <option value={e}>{e} </option>;
                  })
                ) : (
                  <option value="">api error</option>
                )}
              </select>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <select
                id="inputState"
                className="form-select form-select-lg"
                onChange={(e) => {
                  setOption({ ...option, engine_model: e.target.value });
                }}
              >
                <option value="">Model</option>
                {carName ? (
                  carName.map((e) => {
                    return (
                      <option value={e.engine_model}>{e.engine_model} </option>
                    );
                  })
                ) : (
                  <option value="">api error</option>
                )}
              </select>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3">
              <select
                id="inputState"
                className="form-select form-select-lg"
                onChange={(e) => setOption({ ...option, year: e.target.value })}
              >
                <option value="">Year</option>
                {partYear ? (
                  partYear.map((e) => {
                    return <option value={e}>{e} </option>;
                  })
                ) : (
                  <option value="">api error</option>
                )}
              </select>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <select
                id="inputState"
                className="form-select form-select-lg"
                onChange={(e) => {
                  setOption({ ...option, name: e.target.value });
                  // getdataYear(option.make, option.engine_model, e.target.value);
                }}
              >
                <option value="">Parts category</option>
                {partName ? (
                  partName.map((e) => {
                    return <option value={e}>{e} </option>;
                  })
                ) : (
                  <option value="">api error</option>
                )}
              </select>
            </div>
            <div
              className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto"
              style={{ marginTop: "1rem" }}
            >
              <button
                className="btn btn-primary btn-block"
                href="#"
                onClick={handelNavigate}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <p className="a4 text-center" style={{ marginTop: "45px" }}>
          High-Quality Green Parts at Reasonable Prices – Save Money, Save the
          Planet!
        </p>
        <div className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto">
          <Link
            className="btn btn-primary btn-lg"
            to="/book-before"
            style={{ marginTop: "3rem" }}
          >
            Book parts before hand
          </Link>
        </div>
      </div>

      <div className="sell_car_feature">
        <div className="container">
          <h2 className="a5 pd2 text-center">
            Purchasing Used Parts made easy with AKK.
          </h2>
          <div className="row">
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf1}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>1</span>
                </div>
                <h4>Share your Product Requirements</h4>
                <p>
                  Lorem ipsum dolor sit consetetur sadipscing nonumy eirmod
                  teempor invidunt ut.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf2}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>2</span>
                </div>
                <h4>Verify Your Details</h4>
                <p>
                  Lorem ipsum dolor sit consetetur sadipscing nonumy eirmod
                  teempor invidunt ut.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf3}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>3</span>
                </div>
                <h4>Get Quote</h4>
                <p>
                  Lorem ipsum dolor sit consetetur sadipscing nonumy eirmod
                  teempor invidunt ut.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf4}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>4</span>
                </div>
                <h4>Purchase and Confirmation</h4>
                <p>
                  Lorem ipsum dolor sit consetetur sadipscing nonumy eirmod
                  teempor invidunt ut.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h4 className="featured_car1_title">
            Special Offer <Link to="/special-offer">View More</Link>
          </h4>
          <div className="row">
            {offer?.map((item, i) => {
              return (
                <>
                  <div className="col-lg-3 col-md-6" key={i + 1}>
                    <div className="featured_car1">
                      <div
                        className="feature_car1_img"
                        style={{
                          backgroundImage: `url(${
                            item.images.length ? item.images[0] : imgPlaceholder
                          })`,
                          height: "400px",
                          width: "300px",
                        }}
                      ></div>
                      <div className="feature_car1_info">
                        <Link
                          to={`/buy-used-cars/${item._id}`}
                          class="text-truncate"
                          href="#"
                        >
                          {item.car_name}
                        </Link>
                        <div
                          style={{
                            display: "grid",
                            flexDirection: "column",
                            padding: "0.1px",
                            gap: "0px",
                          }}
                        >
                          <p style={{ margin: 0, padding: 0 }}>
                            Tramsmission : {item.engine_type}{" "}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Year : {item?.year}{" "}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Color : {item?.color}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Make : {item?.make}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h4 className="featured_car1_title">
            New Arrivals <Link to="/new-arrival">View More</Link>
          </h4>
          <div className="row">
            {newArrival?.map((item) => {
              return (
                <>
                  <div className="col-lg-3 col-md-6">
                    <div className="featured_car1">
                      <div
                        className="feature_car1_img"
                        style={{
                          backgroundImage: `url(${
                            item.images.length ? item.images[0] : imgPlaceholder
                          })`,
                          height: "400px",
                          width: "300px",
                        }}
                      ></div>
                      <div className="feature_car1_info">
                        <Link
                          to={`/buy-used-cars/${item._id}`}
                          class="text-truncate"
                          href="#"
                        >
                          {item.car_name}
                        </Link>
                        <div
                          style={{
                            display: "grid",
                            flexDirection: "column",
                            padding: "0.1px",
                            gap: "0px",
                          }}
                        >
                          <p style={{ margin: 0, padding: 0 }}>
                            Tramsmission : {item.engine_type}{" "}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Year : {item?.year}{" "}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Color : {item?.color}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Make : {item?.make}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h4 className="a5 text-center">FAQs about Buying Used Car Parts</h4>
          <div className="row">
            <div className="accordion accordion_faq accordion-flush" id="FAQ">
              {renderqna.length !== 0
                ? renderqna.map((e, index) => {
                    const faqId = `faq_${index}`; // Generate a unique ID for each FAQ

                    return (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${faqId}`}
                            aria-expanded="false"
                            aria-controls={faqId}
                          >
                            {e.ques}
                          </button>
                        </h2>
                        <div
                          id={faqId}
                          className="accordion-collapse collapse"
                          data-bs-parent="#FAQ"
                        >
                          <div className="accordion-body">{e.ans}</div>
                        </div>
                      </div>
                    );
                  })
                : "No FAQs available."}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <BurgerMenu />
    </div>
  );
}

export default BuyUsedParts;
