import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";
// import images
import aubnr from "../../img/au_bnr.png";

import image1 from "../../img/image1.webp";
import mission from "../../img/mission.png";
import vision from "../../img/vision.png";
import aabhishek from "../../img/a_abhishek.png";
import akaiho from "../../img/a_kaiho.png";
import AutomotiveScrapProcess from "../../img/Automotive Scrap Process.png";

// components
import Navbar from "../Navbar/Navbar";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import Footer from "../Footer/Footer";

export default function AboutPage() {
  const location = useLocation();
  const currentURL = window.location.href;

  const [data, setData] = useState(null);

  let baseurl = "https://akk.net.in:4000";

  const getdata = async () => {
    try {
      let res = await fetch(`${baseurl}/aboutUs/getAboutUsByUser`);
      let data = await res.json(res);

      setData(data.aboutUs[0]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getdata();
    window.scrollTo(0, 0);
  }, []);
  // console.log(data);
  return (
    <div>
      <Helmet>
        <title>{data ? data.metaTitle : ""}</title>
        <meta name="description" content={data ? data.metaDescription : ""} />
        <meta name="keywords" content={data ? data.metaKeyword : ""} />
        <link rel="canonical" href={currentURL}></link>
        {/* currentURL */}
      </Helmet>
      <Navbar />
      {/* <div class="heading_banner_sm" style="background-image:url(img/au_bnr.png)"> */}
      <div
        className="heading_banner_sm"
        style={{ backgroundImage: `url(${data ? data.bannerImage : ""})` }}
      >
        <div className="container">
          <h1>About Us</h1>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              src={data ? data.topImage : ""}
              alt="About Akk Featured "
              width="100%"
              height="auto"
              loading="lazy"
            />
          </div>
          <div className="col-md-8">
            <h2 className="a5">{data ? data.topTitle : ""}</h2>
            <p className="a4">
              {data ? ReactHtmlParser(data.topDescription) : ""}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        {/* <div class="row" style="margin-top: 6rem;"> */}
        <div className="row" style={{ marginTop: "6rem" }}>
          <div className="col-md-7">
            <h3 className="a5">
              Mission
              <span>{data ? data.missionTitle : ""}</span>
            </h3>
            <p className="a4">
              {data ? ReactHtmlParser(data.missionDescription) : ""}
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={data ? data.missionImage : ""}
              alt="About Akk Featured "
              width="100%"
              height="auto"
              loading="lazy"
            />
          </div>
        </div>
        {/* <div class="row" style="margin-top: 6rem;"> */}
        <div className="row" style={{ marginTop: "6rem" }}>
          <div className="col-md-5">
            <img
              src={data ? data.visionImage : ""}
              alt="About Akk Featured "
              width="100%"
              height="auto"
              loading="lazy"
            />
          </div>
          <div className="col-md-7">
            <h3 className="a5">
              Vision
              <span>{data ? data.vissionTitle : ""}</span>
            </h3>
            <p className="a4">
              {data ? ReactHtmlParser(data.visionDescription) : null}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ marginTop: "6rem" }}>
            <h4 className="a5 text-center">
              <span>{data ? data.abhishekGroupTitle : ""} </span>
            </h4>
            <img
              src={data ? data.abhishekGroupImage : ""}
              width="100%"
              height="auto"
              loading="lazy"
              alt=""
            />
            <div className="a4 pd2">
              {data ? ReactHtmlParser(data.abhishekGroupdescription) : ""}
            </div>
          </div>
          <div className="col-md-6" style={{ marginTop: "6rem" }}>
            <h4 className="a5 text-center">
              <span>{data ? data.kaihoGroupTitle : ""} </span>
            </h4>
            <img
              src={data ? data.kaihoGroupImage : ""}
              width="100%"
              height="auto"
              loading="lazy"
              alt=""
            />
            <div className="a4 pd2">
              {data ? ReactHtmlParser(data.kaihoGroupDescription) : ""}
            </div>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          {/* <h3 class="a1 pd1" style="margin-left: 0;">Automotive Spare Process.</h3> */}
          <h3 className="a1 pd1" style={{ marginLeft: "0" }}>
            {data ? data.sectionTitle : ""}
          </h3>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img
                src={data ? data.sectionImage : ""}
                alt="AKK Purchase Used Automotive Spare Parts"
                width="100%"
                style={{ marginBottom: "2rem" }} // Use double curly braces for inline styles
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="a2 text-highlighter1">
                {data ? ReactHtmlParser(data.sectionDescription) : ""}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          {/* <h3 class="a1 text-center" style="margin-left: 0;"> */}
          <h3 className="a1 text-center" style={{ marginLeft: "0" }}>
            Awards and Recognition.
          </h3>
          <div className="row">
            <div className="col-md-4 mx-auto">
              <div className="a6">
                <h1 style={{ color: "#f6aa00" ,fontWeight:'800'}}  >1st RVSF Haryana</h1>1st
                government authorised car scrapping center in Haryana
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* <BurgerMenu /> */}
    </div>
  );
}
