import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import PaginationComponent from "./PaginationComponent";
import { Link } from "react-router-dom";
import SearchBarForCar from "./SearchBarForCar";
import imgPlaceholder from "../../img/imageP.png";
import NewSearchBar from "./NewSearchBar";
export default function BuyUsedCar() {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({});
  const [selectOptionForSearch, setSelectOptionForSearch] = useState({
    make: "",
    name: "",
    year: "",
  });
  const [carName, setCarName] = useState([]);
  const [carYear, setCarYear] = useState([]);
  const [carMake, setCarMake] = useState([]);

  const [selectOption, setSelectOption] = useState({
    name: "",
    year: "",
    make: "",
  });
  let baseurl = "https://akk.net.in:4000";
  let baseurl2 = "https://akk.net.in:4000";

  // let baseurl = "http://localhost:4000";

  //post  api for inquiry;

  const getdata = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({
          limit: 40,
          page: currentPage,
          ...selectOptionForSearch,
        }), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl}/buyUsedCar/filter-cars`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      if (data?.data?.length == 0) {
        setData([]);

        return;
      }
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  const onPageChange = () => {
    setCurrentPage((p) => p + 1);
    setData("");
  };

  const LoadingSpinner = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "90vh" }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  const handelSearch = () => {
    // console.log(selectOption);
    if (
      selectOptionForSearch.make == "" &&
      selectOptionForSearch.year == "" &&
      selectOptionForSearch.name == ""
    ) {
      alert("Please select an option");
      return;
    }
    getdata();
  };
  console.log(selectOptionForSearch);

  //  get options of search bar;

  const getdataName = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl2}/filter/get-car-name`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setCarName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataYear = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl2}/filter/get-car-year`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      console.log(data);
      setCarYear(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataMake = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl2}/filter/get-make-name`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setCarMake(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getdata();
    getdataName();
    getdataMake();
    getdataYear();
  }, [currentPage]);

  return (
    <div>
      <Navbar />

      <div class="container">
        {/* <!--Breadcrumb--> */}
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Book Parts In Advance
            </li>
          </ol>
        </nav>
        {/* <!--Breadcrumb--> */}
      </div>

      <div class="bg4">
        <div class="container">
          {/* <SearchBarForCar
            selectOption={selectOption}
            setSelectOption={setSelectOption}
            handelSearch={handelSearch}
            name={carName}
            year={carYear}
            make={carMake}
          ></SearchBarForCar> */}
          <NewSearchBar
            selectOptionForSearch={selectOptionForSearch}
            setSelectOptionForSearch={setSelectOptionForSearch}
            handelSearch={handelSearch}
          ></NewSearchBar>
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="row">
                {/* <!--FILTERS--> */}

                {/* <!--FILTERS END--> */}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 mt-2">
              <div class="row">
                {/* <!--PRODUCT LISTINGS--> */}
                {data && data.data && data.data.length == 0
                  ? "No-Active Cars"
                  : ""}
                {data && data.data && data.data.length > 0 ? (
                  <>
                    {data.data.map((item) => {
                      return (
                        <div class="col-lg-4 col-md-6">
                          <div class="featured_car1">
                            <div
                              className="feature_car1_img"
                              style={{
                                backgroundImage: `url(${
                                  item.images && item.images.length > 0
                                    ? item.images[0]
                                    : imgPlaceholder
                                })`,
                                backgroundSize: "cover", // Ensures the image covers the element
                                backgroundPosition: "center", // Centers the image
                              }}
                            ></div>
                            <div class="feature_car1_info2 text-left">
                              <Link
                                to={`/buy-used-cars/${item._id}`}
                                class="text-truncate"
                                href="#"
                              >
                                {item.car_name}
                              </Link>
                              <div
                                style={{
                                  display: "grid",
                                  flexDirection: "column",
                                  padding: "0.1px",
                                  gap: "0px",
                                }}
                              >
                                <p style={{ margin: 0, padding: 0 }}>
                                  Tramsmission : {item.engine_type}{" "}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Year : {item?.year}{" "}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Color : {item?.color}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  Make : {item?.make}
                                </p>
                              </div>
                              <div style={{ float: "right", display: "block" }}>
                                <Link to={`/send-inquiry?id=${item._id}`}>
                                  {" "}
                                  <button class="btn btn-primary btn-sm">
                                    Submit Inquiry
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  // <LoadingSpinner></LoadingSpinner>
                  "No Data Found"
                )}

                {/* <!--PRODUCT LISTINGS END--> */}
                <PaginationComponent
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastPage={data?.totalPages}
                  total={100}
                ></PaginationComponent>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <BurgerMenu />
    </div>
  );
}
