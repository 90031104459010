import React from "react";

import logo from "../../img/logo.png";
import gov1 from "../../newIcon/gov1.png";
import { Link, NavLink } from "react-router-dom";

import { useState } from "react";

export default function Navbar() {
  const [state, setState] = useState(true);

  const handelClick = () => {
    setState(!state);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand" to="/">
            {" "}
            <img src={gov1} height="40" alt="AKK Logo" />{" "}
            <img src={logo} height="40" alt="AKK Logo" />{" "}
          </Link>

          <div className="navbar-nav ms-auto mb-2 mb-lg-0">
            <div className="nav-quick-top">
              <Link
                className="quick-nav"
                style={{ textDecoration: "none", color: "#4E4E4E" }}
                to="/sellcar"
              >
                Sell Your Old Car
              </Link>
              <Link
                className="quick-nav"
                style={{ textDecoration: "none", color: "#4E4E4E" }}
                to="/buy-used-parts"
              >
                {" "}
                Buy Used Parts
              </Link>
            </div>
            {/* <!-- HAMBURGER MENU --> */}
            <div className="hamburger-menu" onClick={handelClick}>
              {/* bar animate */}
              <div className={state ? "bar" : "bar animate"}></div>
            </div>
          </div>
        </div>
      </nav>
      {/* mobile-nav show */}
      <div className={state ? "mobile-nav hide" : "mobile-nav show"}>
        <ul>
          <li>
            {" "}
            <NavLink to="/" activeClassname="active">
              Home
            </NavLink>{" "}
          </li>
          <li>
            {" "}
            <NavLink to="/sellcar" activeClassname="active">
              Sell Your Old Car
            </NavLink>{" "}
          </li>
          <li>
            {" "}
            <NavLink to="/buy-used-parts" activeClassname="active">
              {" "}
              Buy Used Parts
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink to="/buy-used-cars" activeClassname="active">
              {" "}
              Book Parts In Advance
            </NavLink>
          </li>
          {/*  */}
          <li>
            <NavLink to="/aboutus" activeClassname="active">
              {" "}
              About Us
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink
              to="https://blogs.akk.net.in/"
              target="blank"
              activeClassname="active"
            >
              {" "}
              Blogs{" "}
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink to="/news" activeClassname="active">
              News
            </NavLink>{" "}
          </li>

          <li>
            {" "}
            <NavLink to="/contact-us" activeClassname="active">
              Contact Us{" "}
            </NavLink>
          </li>

          <li>
            <div className="row nav_info">
              <div className="col-12">
                <a target="_blank" href="mailto:info@akk.net.in">
                  info@akk.net.in
                </a>

                <a href="tel:+918586932020">+91 85869 32020</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
