import axios from "axios";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./parts.css";
import React, { useEffect, useState } from "react";
import ThumbnailSlider from "./ThumbanilCrousel";

const DetailPageUsedParts = () => {
  const [mainImage, setMainImage] = useState(""); // State to keep track of main image

  const [data, setData] = useState("");
  let url2 = "https://akk.net.in:4000/AkkApi/single-Inventory";

  const fetchData = async () => {
    try {
      const response = await axios.get(url2);
      // console.log(response.data.data, "res");

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle click on thumbnail image
  const handleImageClick = (image) => {
    setMainImage(image); // Set clicked image as main image
  };

  // Dummy data for images

  return (
    <>
      <Navbar />
      <div className="container shadow" style={{ marginTop: "2%" }}>
        <div className="row">
          {/* Left column */}
          <div className="col-md-6 ">
            {/* Main image */}
            {/* <div
              className="text-center main-image-container"
              style={{
                border: "1px solid green",
                width: "100%",
                height: "50%",
                marginTop: "2%",
              }}
            >
              <img
                src={mainImage || "https://via.placeholder.com/500"}
                className="img-fluid main-image"
                alt="Main"
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </div> */}

            {/* Thumbnails */}
            {/* <div
              className="d-flex justify-content-center mt-3 thumbnails-container"
              style={{ overflowX: "auto" }}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className="img-thumbnail mx-1 thumbnail"
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div> */}
            <ThumbnailSlider />
          </div>
          {/* Right column */}
          <div className="col-md-6" style={{ padding: "2%" }}>
            <div className="container">
              <div className="row">
                {/* Car name */}
                <div className="col">
                  <h4>Maruti Waganor R 2010</h4>
                </div>
              </div>
              <div className="row">
                {/* I like car */}
                <div className="col">
                  <p>Lorem, ipsum dolor</p>
                </div>
              </div>
              <div className="row">
                {/* Price */}
                <div className="col">
                  <h4 style={{ color: "#f6a700" }}> ₹ 1000</h4>
                </div>
              </div>
              <div className="row">
                {/* Available in stock */}
                <div className="col">
                  <p>Available in stock</p>
                </div>
              </div>
              <div className="row">
                {/* Description */}
                <div className="col">
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Vel magni, voluptatum ab minima placeat sint accusantium
                    officia dolorem ea ullam voluptatem rem, incidunt natus ipsa
                    ad magnam consectetur. Commodi, repellendus?
                  </p>
                </div>
              </div>
              <div className="row">
                {/* Quantity control */}
                <div className="col">
                  <button
                    style={{
                      width: "10%",
                      height: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    -
                  </button>
                  <span style={{ margin: "4px" }}>1</span>
                  <button
                    style={{
                      width: "10%",
                      height: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="row">
                {/* Send inquiry button */}
                <div className="col" style={{ marginTop: "4%" }}>
                  <button
                    className="btn btn-primary ms-2"
                    style={{ border: "1px solid transparent" }}
                  >
                    {" "}
                    Submit Inquiry
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DetailPageUsedParts;
