 
import { AllRoutes } from './AllRoutes/AllRoutes';

function App() {
  return (
    <div>
     <AllRoutes/>

    </div>
  );
}

export default App;
