import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import axios from "axios";

import PaginationForSearchusedPArt from "./PaginationForSearchusedPArt";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

let baseurl3 = "https://akk.net.in:4000";
export default function SearchBuyUsedParts() {
  const params = new URLSearchParams(window.location.search);
  const make = params.get("make");
  const year = params.get("year");
  const name = params.get("name");
  const engine_model = params.get("engine_model");

  const [carName, setCarName] = useState([]);

  const [partName, setPartName] = useState([]);
  const [partYear, setPartYear] = useState([]);
  const [partMake, setPartMake] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [data, setData] = useState("");

  const [selectOption, setSelectOption] = useState({
    name: name,
    year: year,
    make: make,
    engine_model: engine_model,
  });

  let url2 = "https://akk.net.in:4000/filter/filter-part-v2";

  const fetchData = async (postData) => {
    try {
      const response = await axios.post(url2, {
        page: currentPage,
        ...postData,
      });
      // console.log(response.data.data.data, "res");
      setCurrentPage(response.data.currentPage);
      setLastPage(response.data.totalPages);
      if (response.data.data.length == 0) {
        // alert("No search results found");
        setData([]);
        return;
      }
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCarNameByMAKE = async (value) => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({ make: value }), // Set the request body with limit and page values
      };

      let res = await fetch(
        `${baseurl3}/filter/get-carName-by-makeName-part`,
        requestOptions
      ); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setCarName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (make || year || name || engine_model) {
      fetchData({ make, year, name, engine_model });
      setSelectOption({
        make: make,
        year: year,
        name: name,
        engine_model: engine_model,
      });
    }
  }, []);

  useEffect(() => {
    if (make !== "" || year !== "" || name !== "") {
      fetchData({ ...selectOption });
      // console.log(selectOption,"e")
    }
  }, [currentPage]);

  const onPageChange = () => {
    if (currentPage >= lastPage) {
      return;
    }
    setCurrentPage((p) => p + 1);
    setData("");
  };

  const LoadingSpinner = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "90vh" }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  const handelSearch = () => {
    // console.log(selectOption)
    if (
      selectOption.make == "" &&
      selectOption.year == "" &&
      selectOption.name == ""
    ) {
      alert("Please select an option");
      return;
    }
    fetchData({ ...selectOption, page: 1 });
  };
  // console.log(selectOption,"f")
  const getdataName = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl3}/filter/get-part-name`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setPartName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataYear = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl3}/filter/get-part-year`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      console.log(data);
      setPartYear(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getdataMake = async () => {
    try {
      const requestOptions = {
        method: "POST", // Specify the method as POST
        headers: { "Content-Type": "application/json" }, // Set the Content-Type header
        body: JSON.stringify({}), // Set the request body with limit and page values
      };

      let res = await fetch(`${baseurl3}/filter/get-part-make`, requestOptions); // Pass the options to fetch
      let data = await res.json(); // Parse the response JSON
      // console.log(data);
      setPartMake(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getdataMake();
    getdataYear();
    getdataName();
    if (make !== "") {
      getCarNameByMAKE(make);
    }
  }, []);

  function ftyye(val) {
    switch (val) {
      case "NA":
        return "";
      case "null":
        return "";
        case "g":
        return "Petrol";
        case "G":
        return "Petrol";
        case "D":
          return "Diesel";
          case "d":
            return "Diesel";

      default:
        return "";
    }
  }

  return (
    <div>
      <Navbar />

      <div class="container">
        {/* <!--Breadcrumb--> */}
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Buy Used Part
            </li>
          </ol>
        </nav>
        {/* <!--Breadcrumb--> */}
      </div>
      <div class="bg4">
        <div class="container">
          <div class="row">
            {/* Search  */}
            <SearchBar
              selectOption={selectOption}
              setSelectOption={setSelectOption}
              handelSearch={handelSearch}
              name={partName}
              year={partYear}
              make={partMake}
              carName={carName}
              getCarNameByMAKE={getCarNameByMAKE}
            ></SearchBar>

            {/* Search end */}
            <div class="col-lg-3 col-md-4">
              <div class="row">
                {/* <!--FILTERS--> */}

                {/* <!--FILTERS END--> */}
              </div>
            </div>
            <div cclass="col-lg-12 col-md-12 mt-2">
              <div class="row mt-2">
                {/* <!--PRODUCT LISTINGS--> */}
                {data ? (
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Image</th>

                          <th>Make</th>
                          <th>Car Model</th>
                          <th>Year</th>
                          <th style={{ width: "100px !important" }}>
                            Parts Name
                          </th>
                          <th>Quantity</th>
                          <th>Engine Model</th>
                          <th>Transmission</th>
                          <th>Fuel Type</th>
                          {/* <th>Price</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ padding: "5px" }}>
                        {data?.length === 0
                          ? "No Data Found"
                          : data.map((e, index) => (
                              <tr key={index}>
                                {/* refNo  = stock no*/}
                                <td>
                                  {e?.images?.length > 0 ? (
                                    <img
                                      src={e.images[0]}
                                      width={"50px"}
                                      height={"auto"}
                                      alt="er"
                                    ></img>
                                  ) : (
                                    <img
                                      src="https://png.pngtree.com/png-vector/20220711/ourmid/pngtree-automotive-car-logo-png-image_5837187.png"
                                      width={"50px"}
                                      height={"auto"}
                                      alt="er"
                                    ></img>
                                  )}
                                </td>
                                {/* <td>{e.stock_no}</td> */}
                                {/*  make--*/}
                                <td>{e?.make ?? ""}</td>
                                {/* carModel */}
                                <td>{e?.engine_model}</td>
                                {/* year */}
                                <td>{e?.year}</td>
                                {/*parts name  */}
                                <td>{e?.parts_name ?? ""}</td>
                                {/* qty */}
                                <td>{(+e.qunatity).toFixed(0)}</td>
                                {/*engine model  */}
                                <td>{e?.engine_type ?? ""}</td>
                                {/* transmission */}
                                <td>{e?.transmission}</td>
                                {/* check with client */}
                                <td>{ftyye(e?.fuel_type)}</td>
                                {/* price */}
                                {/* <td>{(+e.price).toFixed(0)}</td> */}
                                <td>
                                  <Link to={`/send-part-inquiry?id=${e._id}`}>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      style={{ fontSize: "10px" }}
                                    >
                                      Submit Inquiry
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>No data</div>
                )}
                {data ? (
                  <div>
                    {" "}
                    {/* {console.log(data, "GFGF")} */}
                    <PaginationForSearchusedPArt
                      onPageChange={onPageChange}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      lastPage={lastPage}
                      total={100}
                    ></PaginationForSearchusedPArt>
                  </div>
                ) : (
                  <div>NoData</div>
                )}
                {/* <!--PRODUCT LISTINGS END--> */}
              </div>
              {/* Add To cart  */}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <BurgerMenu />
    </div>
  );
}
