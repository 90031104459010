import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import { useState } from "react";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import auBnr from "../../img/au_bnr.png";
import { useEffect } from "react";

let base_url = "https://akk.net.in:4000";
export default function Faq() {
  const [faq, setFaq] = useState();
  const [categories, setCategories] = useState();
  const [data, setData] = useState();
  const currentURL = window.location.href;

  // console.log("FAQ", faq);
  // console.log("Categories", categories);
  const getFaq = async () => {
    try {
      let res = await fetch(`${base_url}/faq/getFaq`);
      let data = await res.json(res);
      // Faq question and answer
      setFaq(data.faq);
    } catch (e) {
      console.log(e);
    }
  };

  const getCategories = async () => {
    try {
      let res = await fetch(`${base_url}/faq/getfaqCategories`);
      let data = await res.json(res);
      // faq Categories ;
      setCategories(data.faq);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFaq();
    getCategories();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{data ? data.metaTitle : " "}</title>
        <meta name="description" content={data ? data.metaDescription : " "} />
        <meta name="keywords" content={data ? data.metaKeyword : " "} />
        <link rel="canonical" href={currentURL}></link>
        {/* currentURL */}
      </Helmet>
      <Navbar />

      <div
        className="heading_banner_sm"
        style={{ backgroundImage: `url(${auBnr})` }}
      >
        <div className="container">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>

      {/* used-parts */}

      {categories?.map((el) => {
        return (
          <div className="container">
            <div className="row" style={{ marginTop: "6rem" }}>
              <div className="col-md-9">
                <h2 className="a5">
                  <span>FAQ - {el.name}</span>
                </h2>
                <p className="a4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>

                <div className="row">
                  <div className="accordion accordion_faq accordion-flush" id="FAQ">
                    {faq
                      ?.filter((item) => item.categoryId == el._id)
                      .map((e, index) => {
                        return (
                          <div className="accordion-item" key={index}>
                            {e.faq.map((data, ind) => {
                              const faqId = `faq_${ind}`;
                              return (
                                < >
                                  <h2 className="accordion-header">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#${faqId}`}
                                      aria-expanded="false"
                                      aria-controls={faqId}
                                    >
                                      {/* {console.log("ELE", e.faq)} */}
                                      {data.ques}
                                    </button>
                                  </h2>
                                  <div
                                    id={faqId}
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#FAQ"
                                  >
                                    <div className="accordion-body">
                                      {data.ans}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Footer />

      {/* <BurgerMenu /> */}
    </>
  );
}
