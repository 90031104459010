import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import ReCAPTCHA from "react-google-recaptcha";

import auBnr from "../../img/au_bnr.png";
import { useNavigate } from "react-router-dom";

const obj = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

export default function ContactUs() {
  const [data, setData] = useState();
  const currentURL = window.location.href;
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [form, setForm] = useState(obj);
  const navigate = useNavigate();

  const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    // console.log(form);
  };

  let baseurl = "https://akk.net.in:4000";

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!form.name || !form.email || !form.message || !form.subject) {
      alert("Please fill all field");
      return;
    }

    if (form.phone) {
      if (form.phone.length < 10) {
        alert("Please fill Valid Number");
        return;
      }
    }

    const isAnyFieldEmpty = Object.values(form).some(
      (field) => field.trim() === ""
    );
    const isEmailValidFormat = isEmailValid(form.email);
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA verification");
      return;
    }
    if (isAnyFieldEmpty || !isEmailValidFormat) {
      if (!isEmailValidFormat) {
        alert("Please enter a valid email address");
        return;
      }
      alert("Please fill in all fields");

      return;
    }

    try {
      const response = await fetch(
        "https://akk.net.in:4000/contactUs/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        }
      );
      const data = await response.json();

      if (data.success == "1") {
        alert("Submited");
        navigate("/");
      }
    } catch (e) {
      console.log("ERROR: " + e.message);
    }

    // console.log(form);
  };

  const getContactUsPage = async () => {
    try {
      let res = await fetch(`${baseurl}/contactUs/getContactUsPageByUser`);
      let data = await res.json(res);
      //  console.log(data.faq,"getCategoriesAPI")

      setData(data.contactUsPage);
    } catch (e) {
      console.log(e);
    }
  };
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  useEffect(() => {
    getContactUsPage();
  }, []);
  // console.log(data);
  return (
    <>
      <Helmet>
        <title>{data ? data.metaTitle : " "}</title>
        <meta name="description" content={data ? data.metaDescription : " "} />
        <meta name="keywords" content={data ? data.metaKeyword : " "} />
        <link rel="canonical" href={currentURL}></link>
      </Helmet>
      <Navbar />

      <div
        class="heading_banner_sm"
        style={{ backgroundImage: `url(${data ? data.bannerImage : ""})` }}
      >
        <div class="container">
          <h1>Contact Us</h1>
        </div>
      </div>

      {/* <div class="container">
        <div class="row">
          <div class="col-md-8">
           
          </div>
        </div>
      </div> */}

      {/* <div class="container">
        <div class="row">
          
          <div class="col-md-6">
            <div style={{ height: "400px", border: "1px solid #ccc" }}>
              
            </div>
          </div>

           
          <div class="col-md-6">
            <form>
              <div class="mb-3">
                <label for="name" class="form-label">
                  Name
                </label>
                <input type="text" class="form-control" id="name" />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">
                  Email
                </label>
                <input type="email" class="form-control" id="email" />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">
                  Phone
                </label>
                <input type="tel" class="form-control" id="phone" />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">
                  Subject
                </label>
                <input type="tel" class="form-control" id="phone" />
              </div>
              <div class="mb-3">
                <label for="message" class="form-label">
                  Message
                </label>
                <textarea class="form-control" id="message" rows="4"></textarea>
              </div>
              
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>





        </div>
      </div> */}
      {/* ---- */}

      <div class="container">
        <div class="row">
          {/* Contact Information Section */}
          <div class="col-md-12 mt-4">
            <h2 class="a5">
              <span>Contact Information</span>
            </h2>

            {/* Email */}
            <p>
              <strong>Email: </strong>
              <a target="_blank" href="mailto:info@akk.net.in">
                info@akk.net.in
              </a>
            </p>

            {/* Phone Number */}
            <p>
              <strong>Contact Number: </strong>
              <a href="tel:+918586932020">+91 85869 32020</a>
            </p>
          </div>
        </div>
        <div class="row">
          {/* Map Section */}
          <div class="col-md-6">
            <div style={{ height: "400px", border: "1px solid #ccc" }}>
              <iframe
                style={{
                  width: "100%",
                  height: "400px",
                  border: "1px solid #ccc",
                }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3514.293950819308!2d76.9123407761882!3d28.259102300939105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d39e035086817%3A0x735a6b48fa4d84b7!2sAbhishek%20K%20Kaiho%20Recyclers%20Pvt.%20Ltd.%20(AKK)%20%7C%20Scrap%20car%20recycling%20center%2C%20Delhi%20NCR%2C%20Haryana%2C%20Gurgaon!5e0!3m2!1sen!2sin!4v1706000247611!5m2!1sen!2sin"
                width="100%"
                height="400"
                frameBorder="0"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* Form Section */}
          <div class="col-md-6">
            <form>
              <div class="mb-3 row">
                <div class="col-md-6">
                  <label for="phone" class="form-label">
                    Name
                  </label>
                  <input
                    type="tel"
                    name="name"
                    onChange={handelChange}
                    placeholder="Name"
                    class="form-control"
                    id="phone"
                  />
                </div>
                <div class="col-md-6">
                  <label for="email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handelChange}
                    placeholder="Email"
                    class="form-control"
                    id="email"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <div class="col-md-6">
                  <label for="phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="phone"
                    onChange={handelChange}
                    placeholder="Phone"
                    class="form-control"
                    id="phone"
                  />
                </div>
                <div class="col-md-6">
                  <label for="email" class="form-label">
                    Subject
                  </label>
                  <input
                    name="subject"
                    onChange={handelChange}
                    placeholder="Subject"
                    class="form-control"
                    id="email"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <div class="col">
                  <label for="message" class="form-label">
                    Message
                  </label>
                  <textarea
                    name="message"
                    onChange={handelChange}
                    placeholder="Message"
                    class="form-control"
                    id="message"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <ReCAPTCHA
                sitekey="6LdosFgpAAAAAEulS6fgoy48JE9TG2LNfhvzCdDY"
                onChange={handleRecaptchaChange}
              />
              <button
                type="submit"
                class="btn btn-primary"
                onClick={handelSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />

      {/* <BurgerMenu /> */}
    </>
  );
}
