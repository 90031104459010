import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";

export default function Card() {
  const [data, setData] = useState("");

  let baseurl = "https://akk.net.in:4000";
  const getdata = async () => {
    try {
      let res = await fetch(`${baseurl}/card/getcard`);
      let data = await res.json(res);
      //   console.log(data,"kkkkk");
      setData(data.data);
    } catch (e) {
      console.log(e, "er");
      alert(e);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div className="container">
        <div className="featured-container1">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <h5>{data ? data.title : ""}</h5>
              <p>{data ? ReactHtmlParser(data.description) : ""}</p>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="d-grid gap-2" style={{ paddingTop: "3rem" }}>
                <NavLink
                  className="btn btn-lg btn-dark"
                  to="/sellcar"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Sell Your Old Car
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
