import React, { useState } from "react";

const options = [
  "Used Body Parts",
  "Used Lights",
  "Used Ovrms",
  "Used Tyres",
  "Used Alloys",
  "Used Steering & Suspension",
  "Used Motors",
  "Interior & Trims",
  "Electronics",
  "Engine Cooling System",
  "Cabin Air Conditioner",
];

const MultiSelectDropdown = ({ selectedOptions, setSelectedOptions }) => {
  //   const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const handleSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <label htmlFor="email">Inquiry For?</label>
        <div
          onClick={() => setShowOptions(!showOptions)}
          style={{
            border: "1px solid #212529",
            padding: "10px",
            width: "100%",
            cursor: "pointer",
          }}
        >
          Select Options
        </div>
        {showOptions && (
          <div
            style={{
              border: "1px solid #212529",
              padding: "10px",
              width: "100%",
              maxHeight: "150px",
              overflowY: "scroll",
            }}
          >
            {options.map((option, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleSelect(option)}
                />{" "}
                <label htmlFor={`checkbox-${index}`}> {option}</label>
              </div>
            ))}
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          {selectedOptions.length > 0 && (
            <div>
              <p style={{ fontSize: "1.1rem" }}>Selected Products:</p>
              <ul>
                {selectedOptions.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
