import React from "react";
import { Link } from "react-router-dom";

export default function BurgerMenu() {
  return (
    <div className="mobile-nav hide">
      <ul>
        <li>
          <a className="active" href="/">
            <Link to="/"> Home</Link>
          </a>
        </li>
        <li>
          <a href="#">
            <Link to="/sellcar"></Link>Sell You Car
          </a>
        </li>
        <li>
          <a href="#">
            <Link to="/buy-used-parts"></Link> Buy Used Parts
          </a>
        </li>
        <li>
          <a href="#">
            {" "}
            <Link to="/aboutus"></Link>About Us
          </a>
        </li>
        {/* <li><a href="#">FAQ</a></li> */}
        <li>
          <a href="#">
            {" "}
            <Link to="/bloglist"></Link>Blogs
          </a>
        </li>

        <li>
          <a href="#">
            {" "}
            <Link to="/contact-us">Contact Us</Link>
          </a>
        </li>
        <li>
          <div className="row nav_info">
            <div className="col-lg-4">
              Sales:{" "}
              <a target="_blank" href="mailto:hi@akk.net.in">
                hi@akk.net.in
              </a>
            </div>
            <div className="col-lg-8">
              Support:{" "}
              <a target="_blank" href="mailto:support@akk.net.in">
                support@akk.net.in
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
