import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
// import css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// import images
import sellBgImage from "../../img/sell_bg.png";
import logo from "../../img/logo.png";
import check from "../../img/check.png";
import sf1 from "../../img/sf1.png";
import sf2 from "../../img/sf2.png";
import sf3 from "../../img/sf3.png";
import sf4 from "../../img/sf4.png";
import facebook from "../../img/facebook.png";

// component
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

export default function SellCarPage() {
  const navigate = useNavigate();
  let randomNum;
  const ref = useRef();
  const [showOTP, setShowOTP] = useState(false);
  const [categories, setCategories] = useState([]);
  const [getquesAns, setGetquesAns] = useState([]);
  const [checkOutClicked, setCheckOutClicked] = useState(false);

  const [renderqna, setRenderqna] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    vehicleRegNo: "",
    vehicleLocation: "",
    otp: "",
  });

  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // console.log(randomNum);
  const [page, setPage] = useState();

  const handleVerifyClick = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNo ||
      !formData.vehicleRegNo ||
      !formData.vehicleLocation
    ) {
      alert("Please fill in all fields.");
      return;
    }

    if (formData.phoneNo) {
      if (formData.phoneNo.length < 9 || formData.phoneNo.length > 10) {
        alert("Please enter a contact number with 9 to 10 digits.");
        return;
      }
    }

    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Vehicle registration number format check
    const vehicleRegEx = /^[a-zA-Z0-9]{5,12}$/;

    if (!emailPattern.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!vehicleRegEx.test(formData.vehicleRegNo)) {
      alert(
        "Vehicle registration number should be alphanumeric and between 5 and 12 characters.."
      );
      return;
    }

    //  send api method
    if (!checkOutClicked) {
      return;
    }
    setShowOTP(true);
  };

  let baseurl = "https://akk.net.in:4000";
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.otp != ref.current) {
      alert("Type the correct number");
      // console.log(formData.otp, "##", ref.current);
      return;
    }

    try {
      const response = await fetch(`${baseurl}/inquiry/sellCar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify(formData), // Convert the data to JSON format
      });
      const data = await response.json();
      // alert(data.message)  change in backend after sometime  Inquiry sent to Inquiry submitted successfully.
      alert("Inquiry submitted successfully.");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      navigate("/success");
      // console.log(response); // Parse the response JSON
      // Handle the response data here
      // console.log("Response data:", data);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error:", error);
    }
  };

  const sendOtp = () => {};

  const verifyOTP = () => {};

  const getcategories = async () => {
    try {
      let res = await fetch(`${baseurl}/faq/getfaqCategories`);
      let data = await res.json(res);
      //  console.log(data.faq,"getCategoriesAPI")
      setCategories(data.faq);
      return data.faq;
    } catch (e) {
      console.log(e);
    }
  };

  const getFaqQueAns = async () => {
    try {
      let res = await fetch(`${baseurl}/faq/getFaq`);
      let data = await res.json(res);
      // console.log(data.faq,"QNAgetAPI")
      setGetquesAns(data.faq);
      return data.faq;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    try {
      const categoriesData = await getcategories();
      const faqData = await getFaqQueAns();
      // Now that both API calls have completed, proceed to getQna.
      getqnaTorenderaccordingtocattegories(categoriesData, faqData);
    } catch (e) {
      console.log(e);
    }
  };

  const getqnaTorenderaccordingtocattegories = (categoriesData, faqData) => {
    const catID = categoriesData.find((e) => e.name === "sellcar");

    // console.log(catID, "getqnaTorenderaccordingtocattegories");
    if (catID) {
      // Filter FAQs by category ID
      const filteredData = faqData.find((e) => e.categoryId === catID._id);
      const data = filteredData ? filteredData.faq : [];

      // Set renderqna with the filtered FAQ data
      setRenderqna([...data]);
    }
  };

  const getHomepage = async () => {
    try {
      const response = await fetch(
        "https://akk.net.in:4000/sellcarPage/getsellcarpage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle HTTP errors here
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data.data);
      setPage(data.data);
      if (data.success === 1) {
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const currentURL = window.location.href;

  useEffect(() => {
    fetchData();
    getHomepage();
    ref.current = generateRandomNumber(1, 100);
    window.scrollTo(0, 0);
  }, []);

  // console.log(page.bannerImage)
  return (
    <div>
      <Helmet>
        <title>{page ? page.metaTitle : " "}</title>
        <meta name="description" content={page ? page.metaDescription : " "} />
        <meta name="keywords" content={page ? page.metaKeyword : " "} />
        <link rel="canonical" href={currentURL}></link>
      </Helmet>
      <Navbar />

      <div
        className="heading_banner_auto sell_car_banner"
        style={{
          backgroundImage: `url(${page ? page.bannerImage : ""})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4">
              <ul className="sell_car_banner_info">
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Government Authorized</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Hassle Free</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Instant Payment</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Environmentally Friendly</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Any Conditions of Vehicles</p>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 col-md-8">
              <div className="sell_car_banner_holder">
                <h1 className="text-center">Get Instant Quote.</h1>
                <form onSubmit={handleSubmit}>
                  {showOTP ? null : (
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                            required
                          />
                          <label>Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Your Email ID"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <label>Your Email ID</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Your Contact Number"
                            value={formData.phoneNo}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phoneNo: e.target.value,
                              })
                            }
                            required
                          />
                          <label>Contact Number</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Vehicle Registration Number"
                            value={formData.vehicleRegNo}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                vehicleRegNo: e.target.value,
                              })
                            }
                            required
                          />
                          <label>Vehicle Registration Number</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Vehicle Location"
                            value={formData.vehicleLocation}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                vehicleLocation: e.target.value,
                              })
                            }
                            required
                          />
                          <label>Vehicle Location</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            required
                            onClick={() => setCheckOutClicked(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gridCheck"
                          >
                            By clicking, I agree to the website Privacy Policy.
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {showOTP && (
                    <div className="row g-3">
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {/* OTP send to XXXXXX{formData.phoneNo.substring(formData.phoneNo.length - 4)} */}
                        By continue, I agree to share my personal information
                        and I consent to being contacted based on the
                        information provided.
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "blue",
                        }}
                      >
                        Enter the number {ref.current}
                      </p>
                      <div className="col">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={formData.otp}
                            onChange={(e) =>
                              setFormData({ ...formData, otp: e.target.value })
                            }
                            required
                          />
                          <label>Enter above number here..</label>
                        </div>
                      </div>
                      <div className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto">
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                        }}
                        onClick={sendOtp}
                      >
                        {/* Resend OTP */}
                      </p>
                    </div>
                  )}

                  {!showOTP && (
                    <div className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto">
                      <button
                        className="btn btn-primary"
                        style={{ border: "1px solid transparent" }}
                        type="button"
                        onClick={handleVerifyClick}
                      >
                        Verify
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sell_car_feature">
        <div className="container">
          <h2 className="a5 pd2 text-center">
            Scrap your car made easy with AKK
          </h2>
          <div className="row">
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf1}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>1</span>
                </div>
                <h4>Free car assessment</h4>
                <p>Share your car photos & RC - Get instant evaluation.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf2}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>2</span>
                </div>
                <h4>Instant Payment</h4>
                <p>
                  We'll pay you first and then transport your ELV to our
                  factory.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf3}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>3</span>
                </div>
                <h4>Get Certificate of Deposit (CoD)</h4>
                <p>
                  Get incentive up to 25% of Motor vehicle tax & get your
                  vehicle deregistered (piece of mind!)
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="sell_car_feature_ft">
                <img
                  src={sf4}
                  loading="lazy"
                  height="60"
                  width="auto"
                  alt="Scrap you car with AKK"
                />
                <div className="rank">
                  <span>4</span>
                </div>
                <h4>Free vehicle pickup</h4>
                <p>Get your vehicle picked up as per your convenience</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="featured_box">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 d-md-none d-block">
              <iframe
                width="100%"
                height="300"
                src={
                  page
                    ? page.youTube
                    : "https://www.youtube.com/embed/DWHoXy5eu9U?si=PpwSBrDBxn8yraO3"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-12">
              <h3 className="a5">Why AKK?</h3>
              <ul className="featured_list">
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Government Authorized</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Hassle Free</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Instant Payment</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Environmentally Friendly</p>
                </li>
                <li>
                  <img
                    src={check}
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <p>Any Conditions of Vehicles</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12 d-none d-md-block">
              <iframe
                width="100%"
                height="400"
                src={
                  page
                    ? page.youTube
                    : "https://www.youtube.com/embed/DWHoXy5eu9U?si=PpwSBrDBxn8yraO3"
                }
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto">
            {/* <a class="btn btn-primary btn-lg" href="#" style="margin-top: 3rem;"> */}
            <a
              className="btn btn-primary btn-lg"
              href="#"
              style={{ marginTop: "3rem", border: "1px solid transparent" }}
            >
              Get Instant Web Quote
            </a>
          </div>
        </div>
      </div>

      <div className="bg4">
        <div className="container">
          <h4 className="a5 text-center"> FAQs about Selling Cars</h4>
          <div className="row">
            <div className="accordion accordion_faq accordion-flush" id="FAQ">
              {renderqna.length !== 0
                ? renderqna.map((e, index) => {
                    const faqId = `faq_${index}`; // Generate a unique ID for each FAQ

                    return (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${faqId}`}
                            aria-expanded="false"
                            aria-controls={faqId}
                          >
                            {e.ques}
                          </button>
                        </h2>
                        <div
                          id={faqId}
                          className="accordion-collapse collapse"
                          data-bs-parent="#FAQ"
                        >
                          <div className="accordion-body">{e.ans}</div>
                        </div>
                      </div>
                    );
                  })
                : "No FAQs available."}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {/* <BurgerMenu/> */}
    </div>
  );
}
