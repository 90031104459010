import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams, Link, useNavigate  } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import Card from "../Card/Card"
let baseurl = "https://akk.net.in:4000";

export default function BlogDetail() {
  const [blogDetail, setBlogDetail] = useState("");
  const [random,setRandom]=useState("")

  const { id } = useParams();
  const navigate = useNavigate();
  let token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ODJmNjBkYjY1ZWMzZTQzMzZhNGYxYyIsIm5hbWUiOiJnYXVyYXYiLCJpYXQiOjE3MDMxNDcyMDYsImV4cCI6MTcwMzE5MDQwNn0.nr2XaSbkMDqccgK-TO4QHNz2BEqoR682LM8fDe5jmYk";

  const getOneBlog = async (id) => {
    try {
      const res = await fetch(
        `${baseurl}/blogs/fetchOneBlog?id=${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
           body: JSON.stringify({ token }), // Convert data to JSON string
        }
      );

      if (res.ok) {
        const responseData = await res.json();
        // console.log(responseData);
        setBlogDetail(responseData.oneBlog);
        setRandom(responseData.randomBlog)
        // Handle the response data as needed
      } else {
        console.log("Failed to get a blog.");
       return   navigate('/bloglist');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOneBlog(id);
    window.scrollTo(0, 0);
  }, []);
  const currentURL = window.location.href;
  return (
    <div>
      <Helmet>
        <title>{blogDetail ? blogDetail.metaTitle : ""}</title>
        <meta
          name="description"
          content={blogDetail ?blogDetail.metaDescription : ""}
        />
        <meta
          name="keywords"
          content={blogDetail ? blogDetail.metaKeyword : ""}
        />
         <link rel="canonical" href={currentURL}></link>
      </Helmet>

      <Navbar />
      <div
        className="heading_banner_sm"
        style={{
          backgroundImage: `url(${blogDetail.imageBanner
          })`,
          marginBottom: 0,
        }}
      ></div>

      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="blogs_card_main">
              <div className="blog_intro_container">
                <div className="blog_intro_title">
                  {blogDetail
                    ? blogDetail.title
                    : ""}
                  <small>
                    2
                    {blogDetail
                      ? new Date(blogDetail.createdAt).toDateString()
                      : "Date"}
                  </small>
                </div>
                <img
                  src={blogDetail.imageThumbnail}
                  width="100%"
                  style={{ height: "auto" }}
                  loading="lazy"
                  alt="Blog Title"
                />
                <p className="blog_intro_content">
                  {ReactHtmlParser(blogDetail.description)}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="blogs_card_small">
              <div
                className="blog_banner"
                style={{ background: `url(${random.imageBanner})` }}
              ></div>
              <div className="blog_intro_container">
                <div className="blog_intro_title">
                  {random?random.title:""}
                </div>
                <p className="blog_intro_content">
                  {random?random.body:"Lsorem ipsum dolor sit amet, consectetur adipiscing elit, sed"}
                  d
                </p>
                <a href="" onClick={()=>getOneBlog(random?random.url:id)}> 
                Read More </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Card/>
      <Footer />
    </div>
  );
}
