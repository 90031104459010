import React from "react";

import logo from "../../img/logo.png";
import gov1 from "../../newIcon/gov1.png"
import gov2 from "../../newIcon/gov2.png"
import facebook from "../../img/facebook.png";
import linkdin from "../../newIcon/linkedin.png";
import { Link } from "react-router-dom";
import './footer.css'
export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-xs-12">
            <img src={logo} alt="AKK Company logo" height="100" width="158" />
           
           
            <p class="a4">
              Abhishek K Kaiho Recyclers is the 1st Registered Vehicle Scrapping
              Facility (RVSF) in Haryana. We are the legal and professional
              company providing a solution for End of Life Vehicles
              <br />
              <br />
              Scrap Vehicle Collection Center :<br />
              Registered Vehicle Scraping Facility (RVSF) in Gurugram,
             
              Haryana, Delhi NCR
              <br /> Taoru Road, Village - Fatehpur, Near Bestkoki Automobile
              Pvt. Ltd.,
              <br />
              Distt.- Nuh, Haryana
            </p>
          </div>
          <div class="col-lg-2 col-md-3 col-6">
            <div class="title">Company</div>
            <ul class="list-unstyled footer-nav">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/aboutus">About Us</Link>
              </li>
              <li>
                <Link to="/sellcar">Sell Your Old Car</Link>
              </li>
              <li>
                <Link to="/buy-used-parts">Buy Used Spare Parts</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 col-6">
            <div class="title">Useful Link</div>
            <ul class="list-unstyled footer-nav">
            <li>
                {" "}
                <Link to="https://blogs.akk.net.in/" target="blank"> Blogs </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/frequently-asked-questions"> FAQ </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/privacy-policy">Privacy-Policy </Link>{" "}
              </li>
              {/* <li><a href="#">Usefull Link 3</a></li>
            <li><a href="#">Usefull Link 4</a></li> */}
            </ul>
          </div>
          <div class="col-lg-4 col-md-12">
            <h6 class="a3">
              {/* Taking India to be the leader in Automobile Recycling. */}
              Registered Vehicle Scrapping Facility to Scrap Your Old Car
            </h6>
            <div style={{ display:'grid', marginTop: '5px',gap:"5px" }}>
  <img
    src={gov1}
    alt="AKK Company logo"
    height="100"
    width="258"
    style={{ marginRight: '2px' }}
  />
  {/* <img
    src={gov2}
    alt="AKK Company logo"
    height="100"
    width="258"
  /> */}
</div>
            <div class="">
              <ul class="list-inline social-buttons">
                <li class="list-inline-item">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089324463315"
                    target="_blank"
                  >
                    <img
                      src={facebook}
                      alt="AKK Social"
                      height="30"
                      width="30"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A96051064&keywords=Abhishek%20K%20Kaiho%20Recyclers%20Pvt.%20Ltd.&origin=ENTITY_SEARCH_HOME_HISTORY&sid=4.e"
                    target="_blank"
                    
                  >
                    <img
                      src={linkdin}
                      alt="AKK Social"
                      height="30"
                      width="30"
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
            <p class="sk-action">Powered by <a href="https://studiokrew.com">StudioKrew</a></p>            
            </div>
          </div>
        </div>
        <hr class="text-white" />
        <div class="a2 text-center">
          &copy; AKK {currentYear}  | All Rights Reserved
        </div>
      </div>
    </div>
  );
}
