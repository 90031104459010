import React, { useState } from "react";
import "./searchBar.css"; // Import CSS file for styling

function SearchBar({
  selectOption,
  setSelectOption,
  handelSearch,
  make,
  name,
  year,
  getCarNameByMAKE,
  carName,
}) {
  const [nameOption, setNameOption] = useState(""); // State for the name select
  const [makeOption, setMakeOption] = useState(""); // State for the make select
  const [yearOption, setYearOption] = useState(""); // State for the year select
  // console.log(make, "MAKE", year, "year", name);
  // Event handler for when the name select value changes

  const handleNameChange = (event) => {
    setNameOption(event.target.value); // Update the state with the selected option
    setSelectOption({ ...selectOption, name: event.target.value });
  };

  // Event handler for when the make select value changes
  const handleMakeChange = (event) => {
    setMakeOption(event.target.value); // Update the state with the selected option
    setSelectOption({
      ...selectOption,
      make: event.target.value,
      engine_model: "",
      year: "",
    });
    getCarNameByMAKE(event.target.value);
  };

  // Event handler for when the year select value changes
  const handleYearChange = (event) => {
    setYearOption(event.target.value); // Update the state with the selected option
    setSelectOption({ ...selectOption, year: event.target.value });
  };

  const handelChangeCarName = (event) => {
    setSelectOption({ ...selectOption, engine_model: event.target.value });
  };
  return (
    <div style={{ padding: "1%" }}>
      <div
        className="custom-container row"
        style={{
          border: "1px solid transparent",
          // boxShadow:
          //   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
          boxShadow: "0px -2px 10px 5px rgba(0, 0, 0, 0.15)",
          padding: "2%",
          paddingLeft: "5%",
          borderRadius: "10px",
        }}
      >
        <div className="custom-item col-sm-6 col-md-4 col-lg-3">
          {/* Make {" "} */}
          <select
            style={{ width: "70%", padding: "5px" }}
            value={selectOption?.make}
            onChange={handleMakeChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Maker</option>
            {make ? (
              make.map((e) => {
                return <option value={e}>{e} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>

        <div className="custom-item col-sm-6 col-md-4 col-lg-3">
          <select
            id="inputState"
            className="form-select form-select-lg"
            onChange={handelChangeCarName}
            style={{ width: "70%", padding: "5px" }}
            value={selectOption?.engine_model}
          >
            <option value="">Model</option>
            {carName ? (
              carName.map((e) => {
                return (
                  <option value={e.engine_model}>{e.engine_model} </option>
                );
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>
        <div className="custom-item col-sm-6 col-md-4 col-lg-3">
          {/* Year {" "} */}
          <select
            style={{ width: "70%", padding: "5px" }}
            value={selectOption?.year}
            onChange={handleYearChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Year</option>
            {year ? (
              year.map((e) => {
                return <option value={e}>{e} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>
        <div className="custom-item col-sm-6 col-md-4 col-lg-3">
          {/* Name {" "} */}
          <select
            style={{ width: "70%", padding: "5px" }}
            value={selectOption?.name}
            onChange={handleNameChange}
            className="form-select form-select-lg"
            id="inputState"
          >
            <option value="">Parts category</option>
            {name ? (
              name.map((e) => {
                return <option value={e}>{e} </option>;
              })
            ) : (
              <option value="">api error</option>
            )}
          </select>
        </div>

      
        <div
          className="d-grid gap-2 col-lg-4 col-md-5 col-sm-6 col-xs-8 mx-auto"
          style={{ marginTop: "1rem" }}
        >
          <button
            className="btn btn-primary btn-lg"
            onClick={() => handelSearch()}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
