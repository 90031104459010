import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgPlaceholder from "../../img/imageP.png";

const ThumbnailSlider = ({images}) => {
  const [mainSlider, setMainSlider] = useState(null);
  const dummyImagesAr = [
imgPlaceholder,imgPlaceholder,imgPlaceholder

  ];
  const [dummyImages,setDummyImages] = useState(dummyImagesAr);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    asNavFor: mainSlider,
  };

  useEffect(()=>{
     if(images?.length>0){
      setDummyImages(images)
     }
  },[images])
 

  return (
    <div>
      <Slider {...settings} ref={(slider) => setMainSlider(slider)}>
        {dummyImages?.map((e) => {
          return (
            <div style={{ width: "100%", overflow: "hidden" }}>
              <img src={e} width={"100%"} height="400px" />
            </div>
          );
        })}

        {/* <div>
          <img src="https://spn-sta.spinny.com/blog/20221004191046/Hyundai-Venue-2022.jpg?compress=true&quality=80&w=1200&dpr=2.6" alt="Image 3" />
        </div> */}
        {/* Add more images here */}
      </Slider>
      <div className="thumbnail-slider">
        <Slider
          dots={false}
          infinite={true}
          speed={900}
          slidesToShow={2}
          slidesToScroll={1}
          centerMode={true}
          focusOnSelect={true}
          asNavFor={mainSlider}
          swipeToSlide={true}
          arrows={true}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {dummyImages?.map((e) => {
            return (
              <div>
                <img src={e} width={"100%"} height="100px" />
              </div>
            );
          })}

          {/* <div>
            <img src="image3.jpg" alt="Thumbnail 3" />
          </div> */}
          {/* Add more thumbnails here */}
        </Slider>
      </div>
    </div>
  );
};

export default ThumbnailSlider;
