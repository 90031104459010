import axios from "axios";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./parts.css";
import React, { useEffect, useState } from "react";
import ThumbnailSlider from "./ThumbanilCrousel";
import { Link, useParams } from "react-router-dom";
import imgPlaceholder from "../../img/imageP.png";

const DetailPageUsedCars = () => {
  const { id } = useParams();
  const [mainImage, setMainImage] = useState(""); // State to keep track of main image

  const [data, setData] = useState("");
  let url2 = "https://akk.net.in:4000/buyUsedCar/getcarById";

  const fetchData = async () => {
    try {
      const response = await axios.post(url2, {
        id: id,
      });
      // console.log(response.data.data, "res");

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container " style={{ marginTop: "2%" }}>
        <div className="row">
          {/* Left column */}
          <div className="col-md-6 ">
            {/* Main image */}
            {/* <div
              className="text-center main-image-container"
              style={{
                border: "1px solid green",
                width: "100%",
                height: "50%",
                marginTop: "2%",
              }}
            >
              <img
                src={mainImage || "https://via.placeholder.com/500"}
                className="img-fluid main-image"
                alt="Main"
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </div> */}

            {/* Thumbnails */}
            {/* <div
              className="d-flex justify-content-center mt-3 thumbnails-container"
              style={{ overflowX: "auto" }}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className="img-thumbnail mx-1 thumbnail"
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div> */}
            {data && data.images && data.images.length > 2 ? (
              <ThumbnailSlider images={data?.images} />
            ) : (
              <img src={imgPlaceholder} alt="err"></img>
            )}
          </div>
          {/* Right column */}
          <div className="col-md-6" style={{ padding: "2%" }}>
            <div className="container">
              <div className="row">
                {/* Car name */}
                <div className="col">
                  <h4>
                    {data?.car_name ?? ""} {data?.year ?? ""}
                  </h4>
                </div>
              </div>
              {/* <div className="row">
                
                <div className="col">
                  <p>Lorem, ipsum dolor</p>
                </div>
              </div> */}
              <div className="row">
                {/* Price */}
                <div className="col">
                  <h4 style={{ color: "#f6a700" }}>{data?.price ?? ""}</h4>
                </div>
              </div>
              <div className="row">
                {/* Available in stock */}
                <div className="col">
                  <p>Available in stock</p>
                </div>
              </div>
              <div className="row">
                {/* Description */}
                {/* <div className="col">
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Vel magni, voluptatum ab minima placeat sint accusantium
                    officia dolorem ea ullam voluptatem rem, incidunt natus ipsa
                    ad magnam consectetur. Commodi, repellendus?
                  </p>
                </div> */}
              </div>
              <div className="row">
                {/* Quantity control */}
                {/* <div className="col">
                  <button
                    style={{
                      width: "10%",
                      height: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    -
                  </button>
                  <span style={{ margin: "4px" }}>1</span>
                  <button
                    style={{
                      width: "10%",
                      height: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    +
                  </button>
                </div> */}
              </div>
              <div className="col">
                <p>Manufacture : {data.make}</p>
              </div>
              <div className="col">
                <p>Tramsmission : {data.engine_type}</p>
              </div>
              <div className="row">
                {/* I like car */}
                <div className="col">
                  <p>Color : {data.color}</p>
                </div>
              </div>
              <div className="row">
                {/* Send inquiry button */}
                <div className="col" style={{ marginTop: "4%" }}>
                  <Link to={`/send-inquiry?id=${data._id}`}>
                    <button
                      className="btn btn-primary ms-2"
                      style={{ border: "1px solid transparent" }}
                    >
                      {" "}
                      Submit Inquiry
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DetailPageUsedCars;
