import React from 'react'
import error404 from "../../img/error-404.png"
import {Link} from "react-router-dom"

function NotFound() {
  return (
    <div id="error" style={{width:"100%"}}>


    <div class="error-page container">
        <div class="col-md-8 col-12 offset-md-2">
            <img class="img-error" src={error404} alt="Not Found" width={"100%"} height={"100%"}/>
            <div class="text-center">
                <h1 class="error-title">NOT FOUND</h1>
                <p class='fs-5 text-gray-600'>The page you are looking not found.</p>
        <Link to="/">       <a href="#" class="btn btn-lg btn-outline-primary mt-3">Go Home</a></Link> 
            </div>
        </div>
    </div>


</div>
  )
}

export default NotFound