import { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import auBnr  from "../../img/au_bnr.png"


export default function PrivacyPolicy() {
  const [data, setData] = useState();
  const currentURL = window.location.href;
  return (
    <>
      <Helmet>
        <title>{data ? data.metaTitle : "Meta title"}</title>
        <meta
          name="description"
          content={data ? data.metaDescription : "Meta Description"}
        />
        <meta
          name="keywords"
          content={data ? data.metaKeyword : "Meta  keywords"}
        />
        <link rel="canonical" href={currentURL}></link>
      </Helmet>
      <Navbar />

      <div
        class="heading_banner_sm"
        style={{ backgroundImage: `url(${auBnr})` }}
      >
        <div class="container">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <h2 class="a5">Coming soon ...</h2>
            {/* <p class="a4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              <br></br>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p> */}
          </div>
        </div>
      </div>

      <Footer />

      <BurgerMenu />
    </>
  );
}
